import {Button, Checkbox, Input, Radio, Select} from 'antd'
import React, {useRef, useState} from 'react'
import ButtonStyle from '@/styles/Button.module.scss'
import SelectStyle from '@/styles/Select.module.scss'
import ChooseCreditCard from './ChooseCreditCard'
import Style from '@/styles/Checkout.module.scss'
import {CardBody, CardHead} from "@/pages/Checkout/components/card";

const {Option} = Select
const Payment = ({data, setPayment}) => {

  const [type, setType] = useState('CREDIT_CARD')
  let inputRefs = useRef([])
  inputRefs.current = [0, 0, 0, 0].map(
    (ref, index) => (inputRefs.current[index] = React.createRef())
  )



  const handleSwitchPayment = e => {
    setType(e)
    setPayment({...data, paymentType: e})
  }

  return <div className='relative w-full-half max-w-common-wrap'>
    <CardHead title={'付款方式'}>
      <Radio className={'text-sm sm:text-base mb-3 sm:mb-0'} value={'CREDIT_CARD'} onChange={(e) => handleSwitchPayment(e.target.value)} checked={type === 'CREDIT_CARD'}>信用卡</Radio>
      <Radio className={'text-sm sm:text-base'} value={'ATM_TRANSFER'} onChange={(e) => handleSwitchPayment(e.target.value)} checked={type === 'ATM_TRANSFER'}>ATM轉帳<span className='text-sm sm:text-xs text-primary'>(72小時內付款)</span></Radio>
    </CardHead>

  </div>
}

export default Payment
