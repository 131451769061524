import SwiperCore, {Autoplay} from 'swiper'
import Styles from '@/styles/Home.module.scss'
import AddCartButton from '@/images/promote/common_btn_addcart.png'
import {Swiper, SwiperSlide} from 'swiper/react';
import {useParams} from 'react-router-dom'
import {useHttp} from '@/pages/Promote/core/hooks/useHttp'
import {useEffect, useRef, useState} from 'react'
import {getImageLink, thousandsparator} from '@/pages/Promote/core/helper'
import {useAuth} from '@/pages/Promote/core/hooks/useAuth'
import {useLoading} from '@/hook/useLoading'
import ProductDetail from './components/Detail'
import step1 from '@/images/promote/step1.png'
import step2 from '@/images/promote/step2.png'
import step3 from '@/images/promote/step3.png'
import step4 from '@/images/promote/step4.png'
import {useDistributorService} from "@/pages/Promote/hooks/useDistributorService";

SwiperCore.use([Autoplay])

const warningArr = [
  {
    step: 'step1',
    content: '請先【登入 / 加入會員】',
    icon: step1
  },
  {
    step: 'step2',
    content: '挑選商品加入購物車',
    icon: step2
  },
  {
    step: 'step3',
    content: '購物車結帳',
    icon: step3
  },
  {
    step: 'step4',
    content: '結帳即完成訂單！',
    icon: step4
  }
]

const SectionTitle = ({title, className}) => {
  return <div className={className}>
    <span className={'relative'}>
      <span className={'relative z-1 text-xl font-bold sm:text-3.2xl'}>{title}</span>
      <span className={'inline w-full h-1.5 sm:h-3 bg-yellow absolute bottom-0 sm:-bottom-1 left-1/2 -translate-x-1/2 z-0'}/>
    </span>
  </div>
}

const Promote = () => {
  const img = useRef(null)
  const distributorState = useDistributorService(e => e.distributorState)
  const {setLoading} = useLoading()
  const {distributorApis} = useHttp()
  const addCart = useAuth(e => e.addCart)
  const [current, setCurrent] = useState(null)
  const [visible, setModalVisible] = useState(false)
  const [imgHeight, setImgHeight] = useState(0)
  const {identifier} = useParams()

  useEffect(()=>{
    window.addEventListener('resize',getImgWidth);
    return(()=>{
      window.removeEventListener('resize',getImgWidth);
    })
  },[]);

  useEffect(()=>{
    if(imgHeight === 0){
      getImgWidth()
    }
  },[img.current && imgHeight === 0])

  const getImgWidth = () =>{
    if(img.current?.clientWidth){
      setImgHeight(img.current?.clientWidth)
    }
  }



  const handleGetProductDetail = async (id, preferentialPrice, remainingQuantity) => {
    setLoading(true)
    try {
      const result = await distributorApis.getProduct(id,identifier)
      if (result.success) {
        setCurrent({
          ...result.data, preferentialPrice, remainingQuantity
        })
        setModalVisible(true)
        setLoading(false)
      }
    } finally {
      setLoading(false)
    }
  }

  return distributorState ? <div className="w-full">
    <div className="flex items-center sm:max-w-7.5xl mx-auto">
      <Swiper pagination={false} spaceBetween={50} slidesPerView={1} autoplay={{
        "delay": 2500, "disableOnInteraction": false
      }}>
        {
          distributorState?.advertisingPics.map(item => (
            <SwiperSlide key={item.id}>
              <div className="w-full flex justify-center items-center max-h-80 lg:max-h-160">
                <img
                  className={`w-screen object-cover lg:object-contain`}
                  src={getImageLink(item.id)}
                  alt=''
                />
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
    <div className={'flex justify-center items-center bg-gray8'}>
      <div className={`px-4 sm:px-6 py-5 sm:py-8 sm:m-8 text-center w-full sm:w-7.5xl max-w-common-wrap sm:bg-white rounded-2xl overflow-hidden`}>
        <SectionTitle title={'商品介紹'} />
        <div className={'flex overflow-hidden mt-2 sm:mt-8 flex-col lg:flex-row'}>
          {distributorState?.introductionPicId && <img className='rounded-2xl object-cover w-full lg:w-1/2 lg:mr-6'
                                                       src={getImageLink(distributorState.introductionPicId)}
                                                       alt=""/>}
          <div className={'lg:h-96 mt-3 lg:mt-0 lg:flex-1 text-left overflow-y-auto break-all text-sm sm:text-lg'}>
            {distributorState?.introduction != null
              ? distributorState.introduction
                .split('\n')
                .map((e, i) => e ? <div key={i}>{e}</div> : <div key={i}>&nbsp;</div>)
              : ''}
          </div>
        </div>
      </div>
    </div>
    <div className={'flex justify-center items-center'}>
      <div className={`px-4 sm:px-6 pt-5 pb-4 sm:py-8 sm:m-8 text-center sm:max-w-7.5xl w-full rounded-2xl`}>
        <SectionTitle className={'sm:mb-4'} title={'購買步驟'} />
        <div className={'text-xs sm:text-base text-gray6 mt-3 sm:mt-0'}>訂單送出後，若有疑問請聯繫客服：0800-052-222</div>
        <div className={'flex justify-center flex-wrap overflow-hidden mt-5 sm:mt-8 text-xl'}>
          {
            warningArr.map(item => {
              return (
                <div key={item.step}
                     className={'flex sm:flex-col justify-center items-center mb-3 sm:p-2 w-full sm:w-2/4 lg:w-1/4'}>
                  <div className={'sm:w-full flex justify-center relative '}>
                    <img className={'w-12 h-12 sm:h-24 sm:w-24'} src={item.icon} alt={item.content}/>
                    {
                      item.step !== 'step4' && (<div
                        className={`hidden sm:flex ${item.step === 'step2' ? 'sm:hidden' : "flex"}  lg:flex absolute -right-2 top-1/2 translate-x-1/2`}>
                        <span className={'inline-flex  mr-3 h-2.5 w-2.5 bg-gray5 rounded-full last:mr-0'}/>
                        <span className={'inline-flex  mr-3 h-2.5 w-2.5 bg-gray5 rounded-full last:mr-0'}/>
                        <span className={'inline-flex  mr-3 h-2.5 w-2.5 bg-gray5 rounded-full last:mr-0'}/>
                        <span className={'inline-flex  mr-3 h-2.5 w-2.5 bg-gray5 rounded-full last:mr-0'}/>
                        <span className={'inline-flex   mr-3 h-2.5 w-2.5 bg-gray5 rounded-full last:mr-0'}/>
                      </div>)
                    }

                  </div>
                  <div className={'flex flex-col items-start sm:items-center w-40 sm:w-auto ml-3 sm:ml-0'}>
                    <span className={'font-bold mb-1 sm:my-2 text-xs sm:text-xl'}>{item.step}</span>
                    <span className={'text-sm sm:text-xl'}>{item.content}</span>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>

    <div className={'flex justify-center items-center bg-gray8'}>
      <div className={`px-5 sm:px-0 pt-7 pb-10 sm:pt-8 sm:pb-8 sm:mt-8 text-center w-full sm:w-7.5xl max-w-common-wrap`}>
        <SectionTitle title={'熱門商品'} />
        <div className={'flex flex-wrap overflow-hidden mt-5 sm:mt-8 -mb-5 sm:mb-0'}>
          {
            distributorState?.products.map((item,index) => {
              return (
                <div className={`w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-1.5 md:px-1p xl:px-0.6p mb-3 sm:mb-6`}
                     key={item.productId}>
                  <div className="bg-white rounded-2xl overflow-hidden">
                    <div
                      ref={index === 0 ?img:null}
                      style={{height: `${imgHeight}px`}}
                      className='w-full   cursor-pointer relative flex items-center justify-center'
                      onClick={() => handleGetProductDetail(item.productId, item.preferentialPrice, item.remainingQuantity)}>
                      <img className='w-full h-full object-cover' src={getImageLink(item.coverId)} alt={item.name}/>
                    </div>
                    <div className={'text-xs sm:text-base px-3 py-2 sm:py-4'}>
                      <div
                        className='text-xs h-8 sm:text-lg text-left cursor-pointer sm:h-14 over2line'
                        onClick={() => handleGetProductDetail(item.productId, item.preferentialPrice, item.remainingQuantity)}>
                        {item.name}
                      </div>
                      <div className='flex items-center'>
                        <div className='flex-1'>
                          <div className='flex mt-1 sm:mt-2'>
                            <div
                              className='text-gray1 line-through mr-2'>${thousandsparator(item.suggestedPrice)}</div>
                            <div
                              className="text-primary">${thousandsparator(item.preferentialPrice)}</div>
                          </div>
                        </div>
                        <div>
                          <img src={AddCartButton} alt="" className='cursor-pointer w-5 h-5 sm:w-6 sm:h-6'
                               onClick={() => addCart(item.productId, 1)}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
    <ProductDetail addCart={addCart} current={current} visible={visible} setModalVisible={setModalVisible}/>
  </div> : null
}

export default Promote
