export const createUserApis = instance => ({
  login: data => instance.post('/login', data, { headers: { NoPassToken: true } }),
  loginFB: data => instance.post('/fb/login ',data, { headers: { NoPassToken: true } }),
  userInfo: () => instance.get('/member/get'),
  verifyRegister: data => instance.post('/sms/verifyRegister', data,{ headers: { NoPassToken: true } }),
  verifySMS: data=>instance.post('/sms/verify',data,{ headers: { NoPassToken: true } }),
  sendSMS: data=>instance.post('/sms/send',data,{ headers: { NoPassToken: true } }),
  servicesAndPrivacy: ()=> instance.get('/member/servicesAndPrivacy',{ headers: { NoPassToken: true } }),
  bindFacebook: data=> instance.post('/fbBinding',data,{ headers: { NoPassToken: true } }),
  verifyFbSend: data=> instance.post('/sms/verifyFbSend',data,{ headers: { NoPassToken: true } }),
  verifyFbRegister: data=> instance.post('/sms/verifyFbRegister',data,{ headers: { NoPassToken: true } }),

  bindAppleId: data=> instance.post('/appleIdBinding',data,{ headers: { NoPassToken: true } }),
  verifyAppleIdSend: data=> instance.post('/sms/verifyAppleSend',data,{ headers: { NoPassToken: true } }),
  verifyAppleIdRegister: data=> instance.post('/sms/verifyAppleRegister',data,{ headers: { NoPassToken: true } }),
  loginAppleId: data => instance.post('/apple/login ',data, { headers: { NoPassToken: true } }),

  bindGoogle: data=> instance.post('/googleBinding',data,{ headers: { NoPassToken: true } }),
  verifyGoogleSend: data=> instance.post('/sms/verifyGoogleSend',data,{ headers: { NoPassToken: true } }),
  verifyGoogleRegister: data=> instance.post('/sms/verifyGoogleRegister',data,{ headers: { NoPassToken: true } }),
  loginGoogle: data => instance.post('/google/login ',data, { headers: { NoPassToken: true } }),

  resetVerify: data => instance.post('/sms/resetVerify ',data, { headers: { NoPassToken: true } }),
  verify: data => instance.post('/sms/verify ',data, { headers: { NoPassToken: true } }),
  resetPassword: data => instance.post('/member/resetPassword ',data, { headers: { NoPassToken: true } }),
})
