import Logo from '@/images/logo.svg'
import ButtonStyles from '@/styles/Button.module.scss'
import PromoteStyle from '@/styles/Promote.module.scss'
import {Button, Checkbox, Divider, Form, Input, message} from 'antd'
import Facebook from '@/images/promote/facebook.svg'
import {Link, useHistory} from 'react-router-dom'
import {useHttp} from '@/pages/Promote/core/hooks/useHttp'
import {useAuth} from '@/pages/Promote/core/hooks/useAuth'
import {useDetailLoading} from '@/hook/useDetailLoading'
import {useIdentifierPath} from '@/pages/Promote/core/helper'
import {useState, useEffect, useMemo} from 'react'
import {useLoading} from '@/hook/useLoading'
import useWindowDimensions from '@/hook/useWindowDimensions'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import AppleSignin from 'react-apple-signin-auth';
import { GoogleLogin } from 'react-google-login';

const clientId  =  "546158087890-afk3dgl1m5ft6ljrlkerr0qn4cb80sb4.apps.googleusercontent.com"


const Login = () => {
  const {width} = useWindowDimensions()
  const formWidth = useMemo(() => {
    if (width > 550) {
      return '500px'
    } else {
      return `${width - 32}px`
    }
  }, [width])
  const history = useHistory()
  const [form] = Form.useForm()
  const [checked, setChecked] = useState(false)
  const {userApis} = useHttp()
  const {getIdentifierPath} = useIdentifierPath()
  const setAuth = useAuth(e => e.setAuth)
  const setToken = useAuth(e => e.setToken)
  const rememberMe = useAuth(u => u.rememberMe)
  const setRememberMe = useAuth(u => u.setRememberMe)
  const {confirmLoading, useConfirm} = useDetailLoading()
  const {setLoading} = useLoading()
  const onSubmit = useConfirm(async (d) => {
    if (checked) {
      setRememberMe(d)
    }
    const res = await userApis.login(d)
    if (res.success) {
      const {accessToken, ...auth} = res
      message.success('登入成功')
      setAuth(auth)
      setToken(accessToken)
      history.replace(getIdentifierPath())
    }
  })
  const responseFacebook = async (e) => {
    const {userID, accessToken} = e
    if (userID) {
      try {
        setLoading(true)
        const result = await userApis.bindFacebook({uid: userID})
        if (result.success) {
          const {data: {isVerify, account}} = result
          if (isVerify) {
            const loginResult = await userApis.loginFB({
              accessToken
            })
            if (loginResult.success) {
              setLoading(false)
              const {accessToken, ...auth} = loginResult
              message.success('登入成功')
              setAuth(auth)
              setToken(accessToken)
              history.replace(getIdentifierPath())
            }
          } else {
            setLoading(false)
            message.warning('此帳號尚未註冊,將導入註冊頁面')
            history.push({
              pathname: getIdentifierPath() + '/Register',
              state: {
                account,
                accessTokenData : accessToken,
                isFB: true
              }
            })
          }
        }
      } finally {
        setLoading(false)
      }

    }
  }

  const responseApple = async (response) =>{
    const { authorization } = response
    if ( authorization?.id_token ) {
      try {
        setLoading(true)
        const result = await userApis.bindAppleId({identityToken: authorization?.id_token })
        if (result.success) {
          const {data: {isVerify, account}} = result
          if (isVerify) {
            const loginResult = await userApis.loginAppleId({
              identityToken: authorization?.id_token
            })

            if (loginResult.success) {
              setLoading(false)
              const {accessToken, ...auth} = loginResult
              message.success('登入成功')
              setAuth(auth)
              setToken(accessToken)
              history.replace(getIdentifierPath())
            }
          } else {
            setLoading(false)
            message.warning('此帳號尚未註冊,將導入註冊頁面')
            history.push({
              pathname: getIdentifierPath() + '/Register',
              state: {
                account,
                accessTokenData : authorization?.id_token,
                isAppleId: true
              }
            })
          }
        }else{
          message.warning('此帳號尚未註冊,將導入註冊頁面')
        }
      } finally {
        setLoading(false)
      }

    }

  }

  const responseGoogle = async (response) =>{
    const { googleId , tokenObj : {access_token : token} , profileObj: {email}} = response
    if ( googleId ) {
      try {
        setLoading(true)
        const result = await userApis.bindGoogle({uid: googleId })
        if (result.success) {
          const {data: {isVerify, account}} = result
          if (isVerify) {
            const loginResult = await userApis.loginGoogle({
              accessToken: token
            })
            if (loginResult.success) {
              setLoading(false)
              const {accessToken, ...auth} = loginResult
              message.success('登入成功')
              setAuth(auth)
              setToken(accessToken)
              history.replace(getIdentifierPath())
            }
          } else {
            setLoading(false)
            message.warning('此帳號尚未註冊,將導入註冊頁面')
            history.push({
              pathname: getIdentifierPath() + '/Register',
              state: {
                account,
                accessTokenData : token,
                isGoogle: true,
                email: email
              }
            })
          }
        }
      } finally {
        setLoading(false)
      }

    }
  }

  useEffect(() => {
    if (rememberMe) {
      const {account, password} = rememberMe
      form.setFieldsValue({account, password})
      setChecked(true)
    }
  }, [rememberMe]);


  return (
    <div className="w-full h-full flex flex-col justify-center items-center px-4 pt-10 pb-28 sm:pt-20 sm:pb-24">
      <div>
        <img className={'h-8.5 sm:h-auto'} src={Logo} alt="logo" />
      </div>
      <div className="mt-2 mb-4 text-sm sm:my-4 sm:text-xl">會員登入</div>
      <Form className='login-form' form={form} name={'form'} layout="vertical" style={{width: formWidth}}
            onFinish={onSubmit}>
        <Form.Item
          name={'account'}
          label="帳號"
          rules={[{required: true}]}
          validateTrigger={['onChange', 'onBlur']}
        >
          <Input placeholder='您的手機號碼' inputmode="decimal"/>
        </Form.Item>
        <Form.Item
          label="密碼" name={'password'} rules={[{required: true}]} validateTrigger={['onChange', 'onBlur']}
          className={'mb-3'}
        >
          <Input.Password placeholder="6-12碼英數字"/>
        </Form.Item>
        <div className='flex justify-between text-base mb-10 sm:mb-6'>
          <Checkbox checked={checked} onChange={e => setChecked(e.target.checked)}>記住密碼</Checkbox>
          <Link className={`${PromoteStyle.link}`} to={getIdentifierPath('ForgetPassword')}>忘記密碼?</Link>
        </div>
        <Form.Item>
          <Button type="primary"  htmlType={'submit'} size='large' block
                  loading={confirmLoading}>登入</Button>
        </Form.Item>
        <Divider className={'text-base text-gray1 my-5 sm:my-6'}>OR</Divider>
        <Form.Item>
          <FacebookLogin
            disableMobileRedirect={true}
            appId="3113299205560482"
            callback={responseFacebook}
            render={renderProps => (
              <Button className={`${ButtonStyles.facebook} `} size='large' block onClick={renderProps.onClick}>
                <div className='flex justify-center items-center '>
                  <img className='w-4 h-4 mr-2' src={Facebook} alt=""/>
                  Facebook
                </div>
              </Button>
            )}
          />
        </Form.Item>
        <Form.Item>
          <AppleSignin
            authOptions={{
              clientId: 'yspromote.youngsong.com.tw',
              scope: 'email name',
              redirectURI: window.location.origin,
              nonce: 'nonce',
              usePopup: true,
            }}
            className={`${ButtonStyles.apple} flex justify-center items-center`}
            noDefaultStyle={true}
            buttonExtraChildren="Apple ID"
            onSuccess={(response) => responseApple(response)} // default = undefined
            onError={(error) => console.error(error)} // default = undefined
          />
        </Form.Item>
        <Form.Item>
          <GoogleLogin
            clientId={clientId}
            className={`${ButtonStyles.google} w-full flex justify-center items-center` }
            buttonText="Google"
            onSuccess={(response) => responseGoogle(response)}
            onFailure={(error) => console.log(error)}
            cookiePolicy={'single_host_origin'}
          />
        </Form.Item>
        <div className='text-center text-xs mt-6 sm:text-base sm:mt-12'>
          還不是會員嗎?
          <Link className={`${PromoteStyle.link} mx-2`} to={getIdentifierPath('Register')}>點我註冊</Link>
        </div>
      </Form>
    </div>
  )
}

export default Login
