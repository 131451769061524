import {Button, Form, Input, Radio, Select} from 'antd'
import React, {useEffect, useState} from 'react'
import ChooseReceiver from './ChooseReceiver'
import citys from '@/const/taiwan_districts.json'
import ButtonStyle from '@/styles/Button.module.scss'
import SelectStyle from '@/styles/Select.module.scss'
import {CardBody, CardHead} from "@/pages/Checkout/components/card";

const { Option } = Select
const Receiver = ({ data, setReceiver, receiverList }) => {
  const isMobile = 'ontouchstart' in document.documentElement
  const [modalVisible, setModalVisible] = useState(false)
  const [type, setType] = useState('SAME')
  const [districts, setDistricts] = useState([])
  const handleChangeCity = value => {
    const city = citys.find(item => item.name === value)
    if (city) {
      setDistricts(city.districts)
    }
    setReceiver({
      ...data,
      receiverRegion: value
    })
  }
  const handleChangeDistrict = value => {
    setReceiver({
      ...data,
      receiverTownship: value
    })
  }
  const handelSetReceiver = item => {
    setReceiver({
      ...data,
      sameOrderPerson: null,
      memberReceiverInfoId: item.id,
      receiverRegion: item.region,
      receiverTownship: item.township,
      receiverAddress: item.address,
      receiverName: item.name,
      receiverMobilePhoneNo: item.mobilePhoneNo,
    })
  }
  const handleEditReceiver = (key, value) => {
    const obj = { ...data }
    obj[key] = value
    setReceiver(obj)
  }
  const handleChangeType = e => {
    const type = e.target.value
    setType(type)
    if (type === 'SAME') {
      const { userReceiveInfo } = receiverList
      setReceiver({
        sameOrderPerson: true,
        memberReceiverInfoId: null,
        receiverName: userReceiveInfo.name,
        receiverRegion: userReceiveInfo.region,
        receiverTownship: userReceiveInfo.township,
        receiverAddress: userReceiveInfo.address,
        receiverLocalPhoneNo: '',
        receiverMobilePhoneNo: userReceiveInfo.mobilePhoneNo,
      })
    }
    else if (type === 'NEW') {
      setReceiver({
        sameOrderPerson: null,
        memberReceiverInfoId: null,
        receiverName: '',
        receiverRegion: '',
        receiverTownship: '',
        receiverAddress: '',
        receiverLocalPhoneNo: '',
        receiverMobilePhoneNo: '',
      })
    }
    else if (type === 'LAST') {
      if (receiverList.memberReceiverInfoRecords.length > 0) {
        const lastItem = receiverList.memberReceiverInfoRecords[0]
        setReceiver({
          ...data,
          sameOrderPerson: null,
          memberReceiverInfoId: lastItem.id,
          receiverRegion: lastItem.region,
          receiverTownship: lastItem.township,
          receiverAddress: lastItem.address,
          receiverName: lastItem.name,
          receiverMobilePhoneNo: lastItem.mobilePhoneNo
        })
      }
    }
  }

  useEffect(()=>{
    const { userReceiveInfo } = receiverList
    setReceiver({
      sameOrderPerson: true,
      memberReceiverInfoId: null,
      receiverName: userReceiveInfo.name,
      receiverRegion: userReceiveInfo.region,
      receiverTownship: userReceiveInfo.township,
      receiverAddress: userReceiveInfo.address,
      receiverLocalPhoneNo: '',
      receiverMobilePhoneNo: userReceiveInfo.mobilePhoneNo,
    })
  },[])

  return <div className='mt-3 sm:mt-6 relative w-full-half max-w-common-wrap'>
    <CardHead title={'收件人資料'}>
      <Radio className={'text-sm sm:text-base mb-3 sm:mb-0'} value={'SAME'} onChange={handleChangeType} checked={type==='SAME'}>同訂購人</Radio>
      <Radio className={'text-sm sm:text-base mb-3 sm:mb-0'} value={'NEW'} onChange={handleChangeType} checked={type === 'NEW'}>修改收件人</Radio>
      {receiverList.memberReceiverInfoRecords.length!==0  && <Radio className={'text-sm sm:text-base'} value={'LAST'} onChange={handleChangeType} checked={type === 'LAST'}>從最近收件地址挑選</Radio>}
    </CardHead>
    {((type === 'SAME' && receiverList.userReceiveInfo.address) || type === 'LAST' ) && <CardBody>
      <div className="text-sm sm:text-base">
        <div className='mb-2'>姓名： {data.receiverName}</div>
        <div className='mb-2'>電話： {data.receiverMobilePhoneNo}</div>
        <div>地址： {data.receiverRegion}{data.receiverTownship}{data.receiverAddress}{type === 'LAST' && <Button size="small" className={`${ButtonStyle.gray} ml-2 h-7 sm:h-10`} onClick={() => setModalVisible(true)}>重選</Button>}</div>
      </div>
    </CardBody>}
    {
        (type === 'SAME' && !receiverList.userReceiveInfo.address)&& <CardBody>
          <Form layout="vertical">
            <div className='mb-2'>姓名： {data.receiverName}</div>
            <div className='mb-2'>電話： {data.receiverMobilePhoneNo}</div>
            <Form.Item label="地址">
              <div className='flex'>
                <div className="w-64 pr-2 sm:pr-4">
                  {isMobile ? <div className={SelectStyle.body}><select className="border py-1 px-2 w-full" onChange={(e) => handleChangeCity(e.target.value)}>
                    <option value="">請選擇</option>
                    {citys.map((item, index) => (
                        <option key={`city-${index}`} value={item.name}>{item.name}</option>
                    ))}
                  </select></div> : <Select placeholder="縣市" onChange={(e) => handleChangeCity(e)}>
                    {citys.map((item, index) => (
                        <Option key={`city-${index}`} value={item.name}>{item.name}</Option>
                    ))}
                  </Select>}
                </div>
                <div className="w-64 sm:pr-4">
                  {isMobile ? <div className={SelectStyle.body}>
                    <select className="border py-1 px-2 w-full" onChange={e => handleChangeDistrict(e.target.value)}>
                      <option value="">請選擇</option>
                      {districts.map((item, index) => (
                          <option key={`districts-${index}`} value={item.name}>{item.name}</option>
                      ))}
                    </select>
                  </div> : <Select placeholder="鄉鎮市區" onChange={e => handleChangeDistrict(e)}>
                    {districts.map((item, index) => (
                        <Option key={`districts-${index}`} value={item.name}>{item.name}</Option>
                    ))}
                  </Select>}
                </div>
              </div>
            </Form.Item>
            <Form.Item className={'mb-0'}>
              <Input placeholder='請輸入地址，請勿填寫郵政信箱' value={data.receiverAddress} onChange={(e) => handleEditReceiver('receiverAddress', e.target.value)} />
            </Form.Item>
          </Form>
        </CardBody>
    }
    {
      type === 'NEW' && <CardBody>
        <Form layout="vertical">
          <Form.Item label="姓名">
            <Input placeholder='輸入真實中文姓名'
              onChange={(e) => handleEditReceiver('receiverName', e.target.value)}
              value={data.receiverName}/>
          </Form.Item>
          <Form.Item label="電話">
            <Input
              placeholder='請輸入手機號碼'
              onChange={(e) => handleEditReceiver('receiverMobilePhoneNo', e.target.value)}
              value={data.receiverMobilePhoneNo}
              inputmode="decimal"
            />
          </Form.Item>
          <Form.Item label="地址">
            <div className='flex'>
              <div className="w-64 pr-2 sm:pr-4">
                {isMobile ? <div className={SelectStyle.body}><select className="border py-1 px-2 w-full" value={data.receiverRegion} onChange={(e) => handleChangeCity(e.target.value)}>
                  <option value="">請選擇</option>
                  {citys.map((item, index) => (
                    <option key={`city-${index}`} value={item.name}>{item.name}</option>
                  ))}
                </select></div> : <Select placeholder="縣市" onChange={(e) => handleChangeCity(e)}>
                  {citys.map((item, index) => (
                    <Option key={`city-${index}`} value={item.name}>{item.name}</Option>
                  ))}
                </Select>}
              </div>
              <div className="w-64 sm:pr-4">
                {isMobile ? <div className={SelectStyle.body}>
                  <select className="border py-1 px-2 w-full" value={data.receiverTownship} onChange={e => handleChangeDistrict(e.target.value)}>
                  <option value="">請選擇</option>
                  {districts.map((item, index) => (
                    <option key={`districts-${index}`} value={item.name}>{item.name}</option>
                  ))}
                </select>
                </div> : <Select placeholder="鄉鎮市區" onChange={e => handleChangeDistrict(e)}>
                  {districts.map((item, index) => (
                    <Option key={`districts-${index}`} value={item.name}>{item.name}</Option>
                  ))}
                </Select>}
              </div>
            </div>
          </Form.Item>
          <Form.Item className={'mb-0'}>
            <Input placeholder='請輸入地址，請勿填寫郵政信箱' value={data.receiverAddress} onChange={(e) => handleEditReceiver('receiverAddress', e.target.value)} />
          </Form.Item>
        </Form>
      </CardBody>
    }
    <ChooseReceiver visible={modalVisible} setModalVisible={setModalVisible} data={receiverList.memberReceiverInfoRecords} setReceiver={handelSetReceiver} />
  </div>
}

export default React.memo(Receiver)
