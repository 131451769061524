import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './index.css';
import {BrowserRouter} from 'react-router-dom'
import {renderRoutes} from 'react-router-config'
import routers from './router'
import { MittProvider } from "react-mitt"

const App = () =>{
  // useEffect(()=>{
  //   const initClient = () => {
  //     gapi.client.init({
  //       clientId: process.env.CLIENT_ID,
  //       scope: ''
  //     });
  //   };
  //   gapi.load('client:auth2', initClient);
  // },[])

  return (
    <MittProvider>
      <BrowserRouter>
        {renderRoutes(routers)}
      </BrowserRouter>
    </MittProvider>

  )
}
ReactDOM.render(
  <App/>,
  document.getElementById('root')
);
