import {useEffect, useState} from "react";
import {createContext, useContextSelector} from "use-context-selector";
import {useParams} from "react-router-dom";
import {useLoading} from "@/hook/useLoading";
import {useHttp} from "@/pages/Promote/core/hooks/useHttp";


const context = createContext(null)

export const DistributorProvider = ({children}) => {
  const {identifier} = useParams()
  const {distributorApis} = useHttp()
  const {setLoading} = useLoading()
  const [distributorState, setDistributorState] = useState(null)

  const updateDistributorState = (data) => {
    document.title = data?.salesName
    setDistributorState(data)
  }

  const bootstrap = async () => {
    try {
      if (identifier) {
        setLoading(true)
        const res = await distributorApis.home(identifier)
        if (res.success) {
          updateDistributorState(res.data)
        }
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(bootstrap, [identifier])

  return <context.Provider value={{
    distributorState,
    updateDistributorState
  }}>{children}</context.Provider>
}

export const useDistributorService = (selector) => {
  return useContextSelector(context, selector)
}
