import EffectCoverflow from './coverflow-edit.js';
import { Swiper, SwiperSlide } from 'swiper/react';

import phone1 from '../../images/phone1.png'
import phone2 from '../../images/phone2.png'
import phone3 from '../../images/phone3.png'
import phone4 from '../../images/phone4.png'
import phone5 from '../../images/phone5.png'
import phone6 from '../../images/phone6.png'
import phone7 from '../../images/phone7.png'
import phone8 from '../../images/phone8.png'

import './featureSwiper.sass'

export default function FeatureSwiper () {
  return (
    <Swiper
      className="feature-swiper"
      modules={[EffectCoverflow]}
      spaceBetween={0}
      slidesPerView={6}
      centeredSlides={true}
      loop={true}
      touchEventsTarget={'container'}
      grabCursor={true}
      effect={'coverflow'}
      coverflowEffect={{
        rotate: -12,
        depth: 150,
        slideShadows: false
      }}
      // onSlideChange={(swiper) => console.log(swiper.realIndex, 'slide change')}
      // onSwiper={(swiper) => console.log(swiper, 'hi')}
    >
      <SwiperSlide>
        <img src={phone1} alt="phone-1" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={phone2} alt="phone-2" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={phone3} alt="phone-3" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={phone4} alt="phone-4" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={phone5} alt="phone-5" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={phone6} alt="phone-6" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={phone7} alt="phone-7" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={phone8} alt="phone-8" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={phone1} alt="phone-1" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={phone2} alt="phone-2" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={phone3} alt="phone-3" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={phone4} alt="phone-4" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={phone5} alt="phone-5" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={phone6} alt="phone-6" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={phone7} alt="phone-7" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={phone8} alt="phone-8" />
      </SwiperSlide>
    </Swiper>
  );
};
