import { renderRoutes } from 'react-router-config'
import Styles from '@/styles/Promote.module.scss'
import 'antd/dist/antd.variable.min.css'
import '../../styles/custom.scss'
import { ConfigProvider } from 'antd'
import zhTW from 'antd/lib/locale/zh_TW'
import moment from 'moment';
import 'moment/locale/zh-tw';
import Header from './components/Header'
import Footer from './components/Footer'
import Loading from './components/Loading'
import { AuthProvider } from '@/pages/Promote/core/hooks/useAuth'
import { HttpProvider } from '@/pages/Promote/core/hooks/useHttp'
import { LoadingProvider } from '@/hook/useLoading'
import {DistributorProvider} from "@/pages/Promote/hooks/useDistributorService";
ConfigProvider.config({
  theme: {
    primaryColor: '#C21F3A',
  },
})

moment.locale('zh-tw');

const Promote = ({ route }) => {

  return (
    <ConfigProvider locale={zhTW}>
      <HttpProvider>
        <LoadingProvider>
          <AuthProvider>
            <DistributorProvider>
              <div className={Styles.promote}>
                <Header />
                <div className={Styles.body}>
                  {renderRoutes(route.routes)}
                </div>
                <Footer />
                <Loading />
              </div>
            </DistributorProvider>
          </AuthProvider>
        </LoadingProvider>
      </HttpProvider>
    </ConfigProvider>
  )
}

export default Promote
