import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useEffect, useState } from 'react';

import './banner.sass'
// import bannerTest from '../../images/banner.png'
// import popUpImgTest from '../../images/pop-up-img.png'

// 引入資料
// import bannerJSON from '../../data/banner.json'


function useRWD () {
  const [ device, setDevice ] = useState("mobile")

  const handleRWD = () => {
    if (window.innerWidth > 768)
      setDevice("PC")
    else if (window.innerWidth > 576)
      setDevice("table")
    else
      setDevice("mobile")
  }

  useEffect(() => {
    window.addEventListener('resize', handleRWD)
    handleRWD()

    return (() => {
      window.removeEventListener('resize', handleRWD)
    })
  }, [])

  return device
}


export default function Banner () {
  const device = useRWD()

  // Api 接收
  const [ banner, setBanner ] = useState([])
  useEffect(() => {
      // fetch('api/member/news/getFrontNews')
      //   .then(res => res.json())
      //   .catch(err => {
      //     console.log(err);
      //   })
      //   .then(res => setBanner(res.data.bannerList))
    fetch('api/mall/bannerCarousel/list',{
      headers:{
        'API_VERSION': 'v1.0.0'
      }
    })
      .then(res => res.json())
      .catch(err => {
        console.log(err);
      })
      .then(res => {
        setBanner(res.data)
      })
  }, [])

  return (
    <Swiper className="banner"
      modules={[Navigation]}
      spaceBetween={0}
      slidesPerView={1}
      navigation
      // onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper, 'hi')}
    >
      {
        banner && (
          banner.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  {
                    device === "PC"
                      ? <img src={`api/file/pic/${item.webPicId}`} alt={`banner-${index+1}`} />
                      : <img src={`api/file/pic/${item.appPicId}`} alt={`banner-${index+1}`} />
                  }
                </SwiperSlide>
              )
            })
          )
      }
    </Swiper>
  );
};
