import Styles from '@/styles/Promote.module.scss'
import Cart from '@/images/promote/cart.png'
import Member from '@/images/promote/member.png'
import Logout from '@/images/promote/logout.png'
import {Link, useHistory} from 'react-router-dom'
import {useAuth} from '@/pages/Promote/core/hooks/useAuth'
import {useIdentifierPath} from '@/pages/Promote/core/helper'
import {message} from 'antd'
import {useDistributorService} from "@/pages/Promote/hooks/useDistributorService";

const Header = () => {
  const {getIdentifierPath} = useIdentifierPath()
  const distributorState = useDistributorService(e => e.distributorState)
  const cart = useAuth(e => e.cart)
  const auth = useAuth(e => e.auth)
  const logout = useAuth(e => e.logout)
  const history = useHistory()
  const handleLogout = () => {
    logout()
    message.success('登出成功!')
    history.push({
      pathname: getIdentifierPath(),
    })
  }

  return <div className={`w-full bg-gray2 flex justify-between items-center py-2.5 px-3 md:px-10 sm:py-5`}>
    <Link to={getIdentifierPath()} className={'text-white font-bold text-lg sm:text-3xl'}>
      {distributorState?.salesName || '手舞足道休閒餐旅事業集團'}
    </Link>
    <div className='flex items-center'>
      {auth ?
        <div onClick={handleLogout}>
          <img className="cursor-pointer h-6 w-6 mr-3 sm:mr-4 sm:h-10 sm:w-10" src={Logout} alt=""/>
        </div>
        :
        <Link to={getIdentifierPath('Login')}>
          <img className="cursor-pointer h-6 w-6 mr-3 sm:mr-4 sm:h-10 sm:w-10" src={Member} alt=""/>
        </Link>
      }

      <div className='relative cursor-pointer'>
        <Link to={getIdentifierPath('Cart', true)}>
          <img className="h-6 w-6 sm:h-10 sm:w-10" src={Cart} alt=""/>
        </Link>
        {cart.total ? <div
          className={`absolute text-xs bg-primary rounded-full flex justify-center items-center text-white w-4 w-4 -top-1.5 -right-2 scale-75 sm:-top-0.5 sm:-right-1 sm:scale-100`}>{cart.total}</div> : null}
      </div>
    </div>
  </div>
}

export default Header
