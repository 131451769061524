import { Modal, Button } from 'antd'
import React from 'react'
const ChooseReceiver = ({ visible, setModalVisible, data,setReceiver }) => {
  const handleSave = (item) => {
    setReceiver(item)
    setModalVisible(false)
  }
  return <Modal
    onCancel={() => { setModalVisible(false) }}
    open={visible}
    title="選擇最近收件人"
    footer={null}>
    <div className='h-96 overflow-y-auto px-4' >
      <div className="flex flex-col">
        {data.map((item,index) => <div key={'receiver-'+index} onClick={()=>handleSave(item)} className='border p-4 mb-4 hover:border-primary hover:border-2 cursor-pointer'>
          <div className='mb-2'>姓名： {item.name}</div>
          <div className='mb-2'>電話： {item.mobilePhoneNo}</div>
          <div>地址： {item.region} {item.township} {item.address}</div>
        </div>)}
      </div>
    </div>
    <div className='flex justify-center mt-8'>
      <Button
        type="primary"
        onClick={() => { setModalVisible(false) }}>
        取消
      </Button>
    </div>
  </Modal>
}

export default React.memo(ChooseReceiver)
