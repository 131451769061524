import { createContext, useContext, useMemo } from 'react'
import axios from 'axios'
import { message } from 'antd'
import { createUserApis } from '@/pages/Promote/core/hooks/useHttp/apis/user'
import { createDistributorApis } from '@/pages/Promote/core/hooks/useHttp/apis/distributor'
import { createCartApis } from '@/pages/Promote/core/hooks/useHttp/apis/cart'
import { createCheckoutApis } from '@/pages/Promote/core/hooks/useHttp/apis/checkout'
import { useParams,useHistory} from 'react-router-dom'
import { useMitt } from 'react-mitt'
const context = createContext(null)

export const HttpProvider = ({children}) => {
  const history = useHistory()
  const { identifier } = useParams()
  const { emitter  } = useMitt()
  const http = useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      timeout: 50000,
    })

    instance.interceptors.request.use(
      config => {
        config.headers['API_VERSION']='v1.0.0'
        if (!config.headers.NoPassToken) {
          const token = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_PROJECT_NAME}_token`))
          if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
          }
        }
        return config
      },
      error => {
        return Promise.reject(error)
      }
    )

    instance.interceptors.response.use(
      response => {
        return response.data
      },
      error => {
        if(error.response){
          if(error.response.status === 401){
            message.warning('請先登入',0.8,()=>{
              const prefixPath = `/d/${identifier}`
              emitter.emit('LOG_OUT')
              history.push({
                pathname: prefixPath + '/login'
              })
            })
            return {...error.response.data,success:false};
          }
          if(error.response.data){
            if(error.response.data?.message){
              message.error(error.response.data.message,)
            }
            return {...error.response.data,success:false};
          }
          return {...error.response,success:false};
        }
        message.error(error)
        return {...error,success:false};
      })

    return instance
  }, [])

  const userApis = useMemo(() =>  createUserApis(http), [http])
  const distributorApis = useMemo(() =>  createDistributorApis(http), [http])
  const cartApis = useMemo(() =>  createCartApis(http), [http])
  const checkoutApis = useMemo(() =>  createCheckoutApis(http), [http])


  return <context.Provider value={{
    http,
    userApis,
    distributorApis,
    cartApis,
    checkoutApis,
  }}>{children}</context.Provider>
}

export const useHttp = () => {
  return useContext(context)
}
