import { useEffect, useState } from 'react';
import './lightBox.sass'

export default function LightBox(props) {
  const {data, type, closeLightBox} = props
  const [logo, setLogo] = useState()

    useEffect(() => {
      if (type === "newsContent" && data.id) {
        fetch(`api/member/news/getNewsDetail?id=${data.id}`,{
          headers:{
            'API_VERSION': 'v1.0.0'
          }
        })
          .then(res => res.json())
          .catch(err => {
            console.log(err);
          })
          .then(res => {
            setLogo(res.data.logoId)
          })
      }
    }, [type, data.id])

  return (
    <div className="lightBox-wrap">
      <div className="lightBox">
        <div className="close" onClick={closeLightBox}>
          <div className="line1" />
          <div className="line2" />
        </div>
        { // 最新消息
          type === "newsContent" && (
            <div className="news-wrap">
              <img src={`api/file/pic/${data.picId}`} className="pop-up-img" alt="pop-up-img"/>
              <div className="news-list">
                { logo && (
                  <img src={`api/file/pic/${logo}`} className="company-logo" alt="company-logo"/>
                )}
                <div className="time">{data.publishTime}</div>
                <div className="text-wrap">
                  <h2>{data.title}</h2>
                  <div dangerouslySetInnerHTML={{__html: data.content}} />
                </div>
              </div>
            </div>
          )
        }
        { // 問題答案
          type === "QAContent" && (
            <div className="question-wrap">
              <div className="Q-text-wrap">
                <h2 className="question">Q</h2>
                <h2>{data.question}</h2>
              </div>
              <div className="A-text-wrap">
                <h2 className="answer">A</h2>
                <h4>{data.answer}</h4>
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}
