export const computedPoint = (point) => {
  if (!(typeof point === 'string' || typeof point === 'number')) return point

  const _point = String(point)
  if (!/^\d+\.+\d+/.test(_point)) return point

  const [pre, suf] = _point.split('.')
  if (Number(suf[0]) % 2 === 1) return Number(pre) + 1
  return Number(pre)
}
