import {Button, Checkbox, DatePicker, Form, Input, Select} from 'antd'
import PromoteStyle from '@/styles/Promote.module.scss'
import PrivacyModal from './PrivacyModal'
import {useState, useMemo} from 'react'
import {Link} from 'react-router-dom'
import citys from '@/const/taiwan_districts.json'
import {useHttp} from '@/pages/Promote/core/hooks/useHttp'
import {useIdentifierPath} from '@/pages/Promote/core/helper'
import useWindowDimensions from '@/hook/useWindowDimensions'
import {message} from 'antd'

const {Option} = Select;

const UserInfo = ({nextStep, fromFB , fromApple , fromGoogle, email = ''}) => {
  const {getIdentifierPath} = useIdentifierPath()
  const [districts, setDistricts] = useState([])
  const {userApis} = useHttp()
  const [isAllowPrivacy, setIsAllowPrivacy] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const {width} = useWindowDimensions()
  const formWidth = useMemo(() => {
    if (width > 550) {
      return '500px'
    } else {
      return `${width - 32}px`
    }
  }, [width])
  const handleChangeCity = value => {
    const city = citys.find(item => item.name === value)
    if (city) {
      setDistricts(city.districts)
    }
  }
  const handleRegister = async (d) => {
    if (d.birth) {
      d.birth = d.birth.format('YYYY-MM-DD')
    }
    let res
    if (fromFB) {
      res = await userApis.verifyFbSend(d)
    }else if (fromApple) {
      res = await userApis.verifyAppleIdSend(d)
    }else if (fromGoogle) {
      res = await userApis.verifyGoogleSend(d)
    } else {
      res = await userApis.sendSMS(d)
      if (res.data) {
        message.success(res.data)
      }
    }
    if (res.success) {
      nextStep(d)
    }
  }

  const showPrivacy = () => {
    setModalVisible(true)
  }

  return (
    <>
      <Form initialValues={{email: email}} className='login-form' name={'form'} layout="vertical" style={{width: formWidth}} onFinish={handleRegister}>
        <Form.Item label="姓名" name={'name'} rules={[{required: true}]} validateTrigger={['onChange', 'onBlur']}>
          <Input placeholder='請輸入您的姓名'/>
        </Form.Item>
        <Form.Item label="手機" name={'userPhone'} rules={[{required: true}]} validateTrigger={['onChange', 'onBlur']}>
          <Input inputmode="decimal" placeholder="為您的帳號，共十碼"/>
        </Form.Item>
        {fromFB || fromApple || fromGoogle? <></>
          :
          <>
            <Form.Item label="密碼" name={'password'} required rules={[{
              validator(_, v) {
                if (typeof v !== 'string' || v.length === 0) {
                  return Promise.reject(new Error('請輸入密碼'))
                }
                if (!/^(?=.*[A-z]+)(?=.*\d+).{6,12}$/.test(v)) {
                  return Promise.reject(new Error('密碼格式必須為6-12碼英數字混合'))
                }
                return Promise.resolve()
              }
            }]} validateTrigger={['onChange', 'onBlur']}>
              <Input.Password placeholder="6-12碼英數字混合"/>
            </Form.Item>
            <Form.Item label="密碼確認" name={'password2'} required rules={[({getFieldValue}) => ({
              validator(_, v) {
                if (typeof v !== 'string' || v.length === 0) {
                  return Promise.reject(new Error('請輸入確認密碼'))
                }
                if (v !== getFieldValue('password')) {
                  return Promise.reject(new Error('確認密碼必須與密碼相同'))
                }
                return Promise.resolve()
              }
            })]} validateTrigger={['onChange', 'onBlur']}>
              <Input.Password placeholder="6-12碼英數字混合"/>
            </Form.Item>
          </>
        }

        <Form.Item  label="電子信箱" name={'email'} rules={[{type: 'email'}]}
                   validateTrigger={['onChange', 'onBlur']}>
          <Input placeholder="請輸入您的電子信箱"/>
        </Form.Item>
        <div className='flex'>
          <div className='w-1/2 pr-1 sm:pr-1.5'>
            <Form.Item label="縣市" name={'region'}
                       validateTrigger={['onChange', 'onBlur']}>
              <Select placeholder="縣市"  onChange={(e) => handleChangeCity(e)}>
                {citys.map((item, index) => (
                  <Option key={`city-${index}`} value={item.name}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className='w-1/2 pl-1 sm:pl-1.5'>
            <Form.Item label="鄉鎮" name={'township'}
                       validateTrigger={['onChange', 'onBlur']}>
              <Select placeholder="鄉鎮市區" size='large'>
                {districts.map((item, index) => (
                  <Option key={`districts-${index}`} value={item.name}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </div>

        </div>
        <Form.Item label="地址" name={'address'}
                   validateTrigger={['onChange', 'onBlur']}>
          <Input placeholder="請輸入您的地址" />
        </Form.Item>
        <div className='flex'>
          <div className='w-1/2 pr-1 sm:pr-1.5'>
            <Form.Item label="出生日期" name={'birth'} rules={[{required: true}]} validateTrigger={['onChange', 'onBlur']}>
              <DatePicker className="w-full rounded-lg" placeholder="年/月/日" size='large'
                          format={(value) => value.format('YYYY-MM-DD')}/>
            </Form.Item>
          </div>
          <div className='w-1/2  pl-1 sm:pl-1.5'>
            <Form.Item label="性別" name={'sex'} initialValue={'MALE'}>
              <Select className="flex-1" size='large'>
                <Option value={'MALE'}>男</Option>
                <Option value={'FEMALE'}>女</Option>
              </Select>
            </Form.Item>
          </div>
        </div>

        <div className='flex items-center mb-10 text-xs sm:mb-6 sm:text-base' onClick={showPrivacy} >
          <Checkbox checked={isAllowPrivacy} >我同意</Checkbox>
          <span className={`${PromoteStyle.link} text-xs sm:text-base`}>會員服務及隱私權相關條款</span>
        </div>
        <Form.Item>
          <Button type="primary" className={'border-none'} size='large' block htmlType={'submit'}
                  disabled={!isAllowPrivacy}>下一步</Button>
        </Form.Item>
        <div className='text-center mt-3 -mb-16 text-xs sm:mt-12 sm:mb-0 sm:text-base'>
          已經有帳號了?
          <Link to={getIdentifierPath('Login')}>
            <span className={`${PromoteStyle.link} mx-2`}>馬上登入</span>
          </Link>
        </div>
      </Form>
      <PrivacyModal visible={modalVisible} setModalVisible={setModalVisible} onOK={()=>setIsAllowPrivacy(true)}/>
    </>
  )
}

export default UserInfo
