import React from "react"

const Item = ({ step, num, text, last = false }) => {
  return (
    <>
      <div className={`${step === num ? 'bg-yellow' : 'bg-gray1'} text-white h-7 sm:h-8 w-7 sm:w-8 rounded-full flex justify-center items-center`}>{num}</div>
      <div className={`${step === num ? '' : 'text-gray1'} ml-2`}>{text}</div>
      {last ? null : <div className="w-3 sm:w-16 h-0.5 mx-2 bg-gray5"/>}
    </>
  )
}

const Step = ({ step }) => {
  return (
      <div className="flex w-full justify-center items-center mb-5 sm:mb-10 text-sm sm:text-base max-w-common-wrap">
        <Item step={step} num={'1'} text={'確認明細'} />
        <Item step={step} num={'2'} text={'付款方式'} />
        <Item step={step} num={'3'} text={'完成訂單'} last />
      </div>
  )
}

export default React.memo(Step)
