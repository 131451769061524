import Logo from '@/images/logo.svg'
import Step from '../Cart/components/Step'
import Payment from './components/Payment'
import Receiver from './components/Receiver'
import Invoice from './components/Invoice'
import Notes from "./components/Notes";
import CheckoutDetail from './components/CheckoutDetail'
import {Link, useHistory, useParams} from 'react-router-dom'
import {useHttp} from '@/pages/Promote/core/hooks/useHttp'
import {Checkbox, message} from 'antd'
import {useAuth} from '@/pages/Promote/core/hooks/useAuth'
import React, {useEffect, useMemo, useState} from 'react'
import {useIdentifierPath} from '@/pages/Promote/core/helper'
import {useLoading} from '@/hook/useLoading'
import OUTLYING_ISLAND_LIST from '@/const/outlying.json'

const Checkout = () => {
  const {setLoading} = useLoading()
  const history = useHistory()
  const { checkoutApis } = useHttp()
  const { identifier } = useParams()
  const { getIdentifierPath } = useIdentifierPath()
  const [memberInfo, setMemberInfo] = useState(null)
  const [logisticData, setLogisticData] = useState(null)
  const [receiverList, setReceiverList] = useState(null)
  const [confirm,setConfirm] =useState(false)
  const [payment, setPayment] = useState({
    paymentType: 'CREDIT_CARD',

  })
  const [notes,setNotes] = useState({
    hopeDeliveryTimePeriod:null,
    remark:''
  })
  const [receiver, setReceiver] = useState({
    sameOrderPerson: true,
    memberReceiverInfoId: null,
    receiverName: '',
    receiverRegion: '',
    receiverTownship: '',
    receiverAddress: '',
    receiverLocalPhoneNo: '',
    receiverMobilePhoneNo: '',
  })
  const [invoice, setInvoice] = useState({
    memberInvoiceCarrierId: null,
    invoiceType: 'MEMBER_CARRIER',
    invoiceCarrierType: null,
    isAgreeRecordCarrier: false,
    carrier: null,
    uniformNo: null,
    companyName: null,
    donationUnit: null,
    invoiceAddress: null,
  })
  const [isOutlying, setIsOutlying] = useState('')
  const cart = useAuth(e => e.cart)
  const [shippingFee, setShippingFee] = useState(0)
  const orderTotal = useMemo(() => cart.totalProductAmount + shippingFee, [shippingFee, cart?.totalProductAmount])
  const handleShippingFee = (obj) => {
    let num = 0
    Object.values(obj).forEach(item => {
      if (item.shippingFee) {
        num += item.shippingFee
      }
    })
    setShippingFee(num)
  }
  const initPage = async ()=>{
    try{
      setLoading(true)
      const result = await Promise.all([
        checkoutApis.getMember(),
        checkoutApis.getLogistics(),
        checkoutApis.getReceiverList()
      ])
      setMemberInfo(result[0].data)
      setLogisticData(result[1].data)
      setReceiverList(result[2].data)
    }finally{
      setLoading(false)
    }
  }
  const vaildateCheckout = () => {
    if(!confirm){
      return
    }
    let vaildateText = ''
    if(!receiver.sameOrderPerson){
      if(!(receiver.memberReceiverInfoId || (receiver.receiverRegion && receiver.receiverTownship && receiver.receiverName && receiver.receiverMobilePhoneNo))){
        vaildateText += '收件人資料尚未填寫完成!\r\n'
      }
      if(!/^09[0-9]{8}$/.test( receiver.receiverMobilePhoneNo)){
        vaildateText += '電話號碼格式不正確!\r\n'
      }
    }
    if(receiver.sameOrderPerson){
      if(!(receiver.memberReceiverInfoId || (receiver.receiverRegion && receiver.receiverTownship))){
        vaildateText += '收件人資料尚未填寫完成!\r\n'
      }
    }
    if(invoice.invoiceType === 'PERSONAL_CLOUD_INVOICE'){
      if(!invoice.invoiceCarrierType){
        vaildateText +='個人雲端發票必須選擇任一種載具!\r\n'
      }
    }
    if(vaildateText !== ''){
      message.error(vaildateText)
      return
    }
    handleCheckout()
  }
  const handleCheckout = async () => {
    let checkoutData = {
      ...invoice,
      ...notes,
      totalProductAmount: cart.totalProductAmount,
      totalDeliveryFee: shippingFee,
      orderTotalAmount: cart.totalProductAmount + shippingFee,
      islandType: isOutlying,
      products: __getProducts(),
      distributorIdentifier:identifier,
      paymentType: payment.paymentType
    }
    if(notes.hopeDeliveryTimePeriod===''){
      checkoutData.hopeDeliveryTimePeriod='NOT_SPECIFY'
    }
    if(receiver.sameOrderPerson){
      checkoutData.sameOrderPerson = true
      checkoutData =  {...receiver,...checkoutData}
    }else{
      checkoutData =  {...receiver,...checkoutData}
    }
     if (payment.paymentType === 'CREDIT_CARD') {

      checkoutData.ubotBackURL =  window.location.href.replace('Checkout','Order?')
    } else {
      checkoutData = {
        ...checkoutData,
        ...payment,
      }
    }
    try{
      setLoading(true)
      const result = await checkoutApis.checkout(checkoutData)
      if (result.success) {
        if (payment.paymentType === 'ATM_TRANSFER') {
          history.push({
            pathname: getIdentifierPath() + '/Order',
            query: { id: result.data.id }
          })
        }else if(payment.paymentType === 'CREDIT_CARD'){
          if(result.data.status === "FAIL" || !result.data.creditCardPaymentUrl){
            await history.push({
              pathname:  getIdentifierPath() + '/Order',
              query: { id: result.data.id }
            })
          }else{
            window.location.href = result.data.creditCardPaymentUrl
          }
        }

      }
    }finally{
      setLoading(false)
    }

  }
  const __getProducts = () => {
    return cart.products.map(item => {
      return {
        temperatureLayer: item.temperatureLayer,
        shoppingCartIds: item.products.map(product => product.id),
      }
    })
  }
  useEffect(() => {
    initPage()
  }, [])
  useEffect(() => {
    if (receiver.receiverTownship) {
      setIsOutlying(OUTLYING_ISLAND_LIST.includes(receiver.receiverTownship) ? 'OUTLYING_ISLAND' : 'MAIN_ISLAND')
    }else{
      setIsOutlying('')
    }
  }, [receiver.receiverTownship]);
  useEffect(()=>{
    if(memberInfo && memberInfo.invoiceCarriers){
      const mobileCarrier = memberInfo.invoiceCarriers[0]
      if(mobileCarrier){
        setInvoice({...invoice,invoiceType:'PERSONAL_CLOUD_INVOICE',carrier:`/${mobileCarrier.carrier}`,invoiceCarrierType: mobileCarrier.type})
      }
    }
  },[memberInfo])
  return (
    <div className={`w-full h-full flex flex-col justify-center items-center pb-10 sm:pb-28 pt-10 sm:pt-20 text-base`}>
      <div>
        <img src={Logo} alt="" className={'w-32 sm:w-44'} />
      </div>
      <div className="text-xl my-4">購物車</div>
      <Step step={"2"} />
      <Payment data={payment} setPayment={setPayment}  />
      {receiverList && <Receiver data={receiver} setReceiver={setReceiver} receiverList={receiverList} />}
      <Notes data={notes} setNotes={setNotes}/>
      <Invoice data={invoice} setInvoice={setInvoice} defaultCarrier={memberInfo?.invoiceCarriers ? memberInfo.invoiceCarriers : null} />
      {logisticData && <CheckoutDetail orderTotal={orderTotal} logisticData={logisticData} isOutlying={isOutlying} handleShippingFee={handleShippingFee} />}
      <div className='text-align mt-4 w-full-half max-w-common-wrap'>
        <Checkbox className='mb-2' checked={confirm} onChange={(e)=>setConfirm(e.target.checked)}>我同意辦理退貨時，由知足饗樂代為處理發票及銷貨退回證明單，以加速退貨退款作業。</Checkbox>
      </div>
      <div className='mt-10 sm:mt-12 flex justify-center flex-col sm:flex-row w-full-half max-w-common-wrap'>
        <Link className={`px-16 py-3 rounded-lg border bg-gray2 text-white text-base sm:mr-2 cursor-pointer text-center order-3 sm:order-1 mt-2 sm:mt-0`} to={getIdentifierPath('Cart', true)}>上一步</Link>
        <div className={`px-16 py-3 rounded-lg border sm:ml-4 text-center cursor-pointer order-2 ${confirm? 'bg-primary text-white' : 'bg-red-300 text-white' }`} onClick={vaildateCheckout}>確認結帳</div>
      </div>
    </div>
  )
}

export default Checkout
