import { useEffect, useState } from 'react'
import SVGA from 'svgaplayerweb'
import $ from 'jquery'
import logo from '@/images/logo.svg'
import android from '@/images/btn_download_android.svg'
import ios from '@/images/btn_download_ios.svg'
import feature_svga01 from '@/svga/feature_icon01.svga'
import feature_svga02 from '@/svga/feature_icon02.svga'
import feature_svga03 from '@/svga/feature_icon03.svga'
import feature_svga04 from '@/svga/feature_icon04.svga'
import descript from '@/images/descript.svg'
import hint from '@/images/hint.svg'
import more from '@/images/more.svg'
import gotop from '@/images/gotop.svg'
import btn_ilovestew from '@/images/btn_ilovestew.svg'
import btn_yin from '@/images/btn_yin.svg'
import btn_Samoa from '@/images/btn_Samoa.svg'
import btn_OMEGA from '@/images/btn_OMEGA.svg'
import decoration_5 from '@/images/decoration_5.svg'
import decoration_6 from '@/images/decoration_6.svg'

import './style.sass';
import LightBox from '@/components/lightBox/lightBox'
import Banner from '@/components/Banner/Banner'
import FeatureSwiper from '@/components/FeatureSwiper/FeatureSwiper'

// 引入資料
// import newsContentJSON from './data/newsContent.json'
// import QAContentJSON from './data/QAContent.json'


export default function () {
  const [showLightBox, setShowLightBox] = useState(false)
  const [currentLightBox, setCurrentLightBox] = useState({})
  const [lightBoxType, setLightBoxType] = useState(null)

  // SVGA 動畫渲染
  useEffect(() => {
    const feature_player01 = new SVGA.Player('#feature_svga01')
    const feature_parser01 = new SVGA.Parser('#feature_svga01')
    feature_parser01.load(feature_svga01, function(videoItem) {
      feature_player01.setVideoItem(videoItem)
      feature_player01.startAnimation()
    })
    const feature_player02 = new SVGA.Player('#feature_svga02')
    const feature_parser02 = new SVGA.Parser('#feature_svga02')
    feature_parser02.load(feature_svga02, function(videoItem) {
      feature_player02.setVideoItem(videoItem)
      feature_player02.startAnimation()
    })
    const feature_player03 = new SVGA.Player('#feature_svga03')
    const feature_parser03 = new SVGA.Parser('#feature_svga03')
    feature_parser03.load(feature_svga03, function(videoItem) {
      feature_player03.setVideoItem(videoItem)
      feature_player03.startAnimation()
    })
    const feature_player04 = new SVGA.Player('#feature_svga04')
    const feature_parser04 = new SVGA.Parser('#feature_svga04')
    feature_parser04.load(feature_svga04, function(videoItem) {
      feature_player04.setVideoItem(videoItem)
      feature_player04.startAnimation()
    })
  }, [])

  // Api 接收
  const [ newsContent, setNewsContent ] = useState([])
  const [ QAContent, setQAContent ] = useState([])
  useEffect(() => {
      fetch('api/member/news/getPushNotice',{
        headers:{
          'API_VERSION': 'v1.0.0'
        }
        })
        .then(res => res.json())
        .catch(err => {
          console.log(err);
        })
        .then(res => setNewsContent(res.data.content))

      fetch('api/qa/getAll',{
        headers:{
          'API_VERSION': 'v1.0.0'
        }
      })
        .then(res => res.json())
        .catch(err => {
          console.log(err);
        })
        .then(res => setQAContent(res.data))
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleGotopPosition)
    return ()=>{
      window.removeEventListener('scroll', handleGotopPosition)
    }
  }, [])

  // 判斷視窗高度決定 gotop 按鈕顯示
  const handleGotopPosition = () => {
    const gotop = document.getElementsByClassName('gotop')[0]
    const body = document.getElementsByTagName('body')[0]
    const footer = document.getElementsByClassName('footer')[0]
    if (window.scrollY > 700 && window.scrollY + window.innerHeight < body.offsetHeight - footer.offsetHeight )
      gotop.style.position = "fixed"
    else
      gotop.style.position = "absolute"
  }

  const openLightBox = (item, type) => {
    setShowLightBox(true)
    setCurrentLightBox(item)
    setLightBoxType(type)
  }

  const closeLightBox = () => {
    setShowLightBox(false)
  }

  const gotopOnClick = () => {
    $('html,body').animate({ scrollTop: 0 }, 'slow')
  }

  return (
    <div className="App">

      <header className="header">
        <img src={logo} className="App-logo inline" alt="logo" />
      </header>

      <Banner />

      <div className="container-wrap">
        <div className="experience">
          <div className="tittle-group">
            <div className="tittle-EN">Experience</div>
            <div className="tittle-TW">會員APP馬上體驗</div>
          </div>
          <p className="text">知足饗樂是一款具備多功能的生活必備 APP！不管是會員卡、探索主題美食、累積饗樂點數、預約訂位等，輕鬆掌管各店家的點數與優惠，到店消費，不僅能累積點數兌換商品，還能折抵現金，並第一手接收各種最新消息及貼心通知！現在開始，打造你的知足饗樂生活</p>
          <div className="button-group">
            <a href={'https://apps.apple.com/us/app/%E7%9F%A5%E8%B6%B3%E9%A5%97%E6%A8%82-%E5%85%A8%E6%96%B9%E4%BD%8D%E4%BC%91%E9%96%92%E9%A4%90%E9%A3%B2%E9%9B%86%E5%9C%98/id1635096911'} target={'_blank'}>
              <img src={ios} className="apple-store" alt="apple-store"/>
            </a>
            <a href={'https://play.google.com/store/apps/details?id=com.rntmp'} target={'_blank'}>
              <img src={android} className="google-play" alt="google-play"/>
            </a>


          </div>
          <img id="decoration_5" src={decoration_5} alt="decoration_5" />
          <div className="box-list">
            <div className="box">
              <div id="box-title-1" className="box-title-wrap">
                <h3>知足饗樂卡</h3>
              </div>
              <p>加入會員，享受更多專屬於你的優惠及服務</p>
              <div id="feature_svga01" className="svga" />
            </div>
            <div className="box">
              <div id="box-title-2" className="box-title-wrap">
                <h3>點數累積</h3>
              </div>
              <p>消費即時積點及紀錄查詢，不定時推出好康活動</p>
              <div id="feature_svga02" className="svga" />
            </div>
            <div className="box">
              <div id="box-title-3" className="box-title-wrap">
                <h3>線上商城</h3>
              </div>
              <p>24小時購物不打烊，走到哪逛到哪</p>
              <div id="feature_svga03" className="svga" />
            </div>
            <div className="box">
              <div id="box-title-4" className="box-title-wrap">
                <h3>品牌情報</h3>
              </div>
              <p>第一手接收到品牌相關情報，掌握最新消息</p>
              <div id="feature_svga04" className="svga" />
            </div>
          </div>
        </div>

        <div className="features">
          <img id="decoration_6" src={decoration_6} alt="decoration_6" />
          <div className="bg-wrap">
            <div className="tittle-group">
              <div className="tittle-EN">Features</div>
              <div className="tittle-TW">APP特色功能</div>
            </div>
            <p className="text">直覺且人性化的操作介面，只要輕輕一按，就能掌握專屬於你的優惠及服務</p>

            <FeatureSwiper />
            <div className="swiper-descript">
              <img src={descript} className="descript" alt="descript" />
              <img src={hint} alt="hint" />
            </div>
          </div>

          <div className="menu-group">
            <div className="menu">
              <div className="menu-tittle">
                <div className="tittle-EN">News</div>
                <div className="tittle-TW">最新消息</div>
              </div>
              <div className="menu-list-group">
                { newsContent.length > 0 && (
                  newsContent.map((item, index) => {
                    return (
                      <div key={index} className="menu-list news-list" onClick={() => openLightBox(item, "newsContent")}>
                        <div className="time">{item.publishTime}</div>
                        <div className="text-wrap">
                          <h3>{item.title}</h3>
                          <div className="menu-text" dangerouslySetInnerHTML={{__html: item.content}} />
                          <img src={more} className="more" alt="more" />
                        </div>
                      </div>
                    )
                  })
                )}
              </div>
            </div>
            <div className="menu">
              <div className="menu-tittle">
                <div className="tittle-EN">Q&A</div>
                <div className="tittle-TW">常見問題</div>
              </div>
              <div className="menu-list-group">
                { QAContent.length > 0 && (
                  QAContent.map((item, index) => {
                    return (
                      <div key={index} className="menu-list QA">
                        <span className="name">{item.qatype}</span>
                        <div className="text-wrap">
                          {item.qaResponseItemList.map((item, index) => {
                            return (
                              <div key={index} className="QA-text-wrap" onClick={() => openLightBox(item, "QAContent")}>
                                <h2 className="question">Q</h2>
                                <h3>{item.question}</h3>
                                <img src={more} alt="more" />
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })
                )}
              </div>
            </div>
          </div>
          <img src={gotop} className="gotop" alt="gotop" onClick={gotopOnClick} />
        </div>

      </div>

      <div className="footer">
        <div className="icon-group">
            <a href="https://ilovestew.com.tw" rel="noreferrer noopener" target="_blank">
              <img src={btn_ilovestew} alt="btn_ilovestew" />
            </a>
            <a href="https://samoafoods.com.tw" rel="noreferrer noopener" target="_blank">
              <img src={btn_Samoa} alt="btn_Samoa" />
            </a>
            <a href="https://omegafitness.com.tw" rel="noreferrer noopener" target="_blank">
              <img src={btn_OMEGA} alt="btn_OMEGA" />
            </a>
            <a href="https://yinhotpot.com" rel="noreferrer noopener" target="_blank">
              <img src={btn_yin} alt="btn_yin" />
            </a>
        </div>
        <div className="contact">
          <div className="company-name">手舞足道休閒餐旅事業集團</div>
          <div className="phone">TEL / 0800-052-222</div>
          {/* <div className="address">ADD/台中市台灣大道二段239號B1</div> */}
          {/* <div className="webside">WEB/https://omegafitness.com.tw/about-us</div> */}
        </div>
        <div className="copyright">Copyright ©  知足饗樂  All rights reserved.</div>
        <div className="copyright">23.03.14.0.7.1</div>
      </div>

      { // News 與 Q&A 的彈出視窗
      showLightBox && (
        <LightBox
          data={currentLightBox}
          type={lightBoxType}
          closeLightBox={closeLightBox}
        />
      )}
    </div>
  );
}
