import Styles from '@/styles/Promote.module.scss'

const Footer = () =>{
  return (
    <div className={Styles.footer}>
      <div className='flex flex-wrap justify-center'>
        <span className='mb-1 sm:mb-2'>手舞足道休閒餐旅事業集團</span>
        <span>TEL / 0800-052-222</span>
      </div>
      <div className={Styles.copyright}>
      Copyright ©  知足饗樂  All rights reserved.
      </div>
    </div>
  )
}

export default Footer
