import {Form, Input, Button} from 'antd'
import ButtonStyles from '@/styles/Button.module.scss'
import PromoteStyle from '@/styles/Promote.module.scss'
import {useEffect, useState, useMemo} from 'react'
import {WarningFilled} from '@ant-design/icons'
import useWindowDimensions from '@/hook/useWindowDimensions'

const COUNTDOWN_SECOND = 30

const SendMessage = ({ handlePhone ,forgetPassword, handleSendMessage, registerInfo, handleResetPhone, hasError}) => {
  const [countdown, setCountdown] = useState(COUNTDOWN_SECOND)
  const [verifyCode, setVerifyCode] = useState('')
  const [phone, setPhone] = useState('')
  const [phoneVisible, setPhoneVisible] = useState(false)
  const {width} = useWindowDimensions()
  const formWidth = useMemo(() => {
    if (width > 550) {
      return '500px'
    } else {
      return `${width - 32}px`
    }
  }, [width])

  const handleVerify = () => {
    handleSendMessage(verifyCode)
  }

  const resendVerification = (newPhone) => {
    if (newPhone) {
      handleResetPhone(phone)
    } else {
      handleResetPhone()
    }
    setPhoneVisible(false)
    reCountdown()
  }

  const handleShowPhone = () => {
    !forgetPassword ? setPhoneVisible(true) : handlePhone()
  }

  const reCountdown = () => {
    let timer
    setCountdown(COUNTDOWN_SECOND)
    timer = setInterval(() => {
      setCountdown(e => e - 1)
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }
  const handleChange = (e) => {
    const { value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      setVerifyCode(inputValue);
    }
  };

  useEffect(reCountdown, [])

  return (
    <Form className='login-form' layout="vertical" style={{width: formWidth}}>
      {!phoneVisible ?
        <Form.Item label="手機驗證碼"
        >
          <Input onChange={handleChange} inputmode="decimal" placeholder='請輸入手機驗證碼' value={verifyCode} maxLength="5"/>
          {
            !hasError ?
              <div className='text-gray4 mt-2 text-xs sm:text-base'>
                您的手機尚未驗證，已寄送驗證碼至 {registerInfo.userPhone}，若於30秒後仍未收到驗證簡訊，請點選「{countdown >= 0 ? `重新寄送驗證碼(${countdown})`: <span
                className={PromoteStyle.link} onClick={() => resendVerification(false)}>重新寄送驗證碼</span>}」!
              </div>
              :
              <div className='flex justify-between items-center mt-2 text-xs sm:text-base'>
                <div className="flex items-center text-red-600"><WarningFilled className='mr-1'/>手機驗證碼錯誤</div>
                {countdown >= 0 ? <span>重新寄送驗證碼({countdown})</span> : <span className={`${PromoteStyle.link}`} onClick={() => resendVerification(false)}>重新寄送驗證碼</span>}
              </div>
          }
        </Form.Item>
        :
        <Form.Item label="手機號碼"
        >
          <Input inputmode="decimal" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder='請輸入手機號碼'/>
        </Form.Item>
      }
      <Form.Item className={'mt-16'}>
        <div className="flex">
          {
            !phoneVisible ?
              <div className={'flex w-full flex-col sm:flex-row'}>
                <Button className={`${ButtonStyles.gray} mb-2 sm:mr-4`} block onClick={handleShowPhone}>修改手機號碼</Button>
                <Button block type="primary" onClick={handleVerify}>驗證</Button>
              </div>
              :
              <Button size='large' block type="primary" onClick={() => resendVerification(true)}>寄信</Button>
          }
        </div>
      </Form.Item>
    </Form>
  )
}

export default SendMessage
