import {Modal, Button, InputNumber, Tabs, message} from 'antd'
import {PlusOutlined, MinusOutlined} from '@ant-design/icons'
import React, {useEffect, useState} from 'react'
import SwiperCore, {Autoplay} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react';
import {thousandsparator} from '@/pages/Promote/core/helper'
import islands from '@/const/island.json'
import payments from '@/const/payment.json'
import {useAuth} from '@/pages/Promote/core/hooks/useAuth'
import cardImg from '@/images/promote/shop_img_card.png'
import transportImg from '@/images/promote/shop_img_transport.png'
import temperatures from '@/const/temperature.json'
import ModalClose from "@/images/promote/modal_close.png";

const {TabPane} = Tabs
SwiperCore.use([Autoplay])
const ProductDetail = ({current, setModalVisible, visible, addCart}) => {
  const [amount, setAmount] = useState(1)
  const cart = useAuth(e => e.cart)
  const getCurrentStock = () => {
    let stock = 0
    cart.products.forEach(item => {
      const product = item.products.find(product => {
        return product.productId === current.id
      })
      if (product) {
        stock = product.quantity
      }
    })
    return stock
  }
  const getShipping = () => {
    let mixins = []
    if (current.shippingMethods) {
      current.shippingMethods.forEach(item => {
        const island = islands.find(islandItem => islandItem.id === item.islandType)
        const temperature = temperatures.find(temperItem => temperItem.id === item.temperatureLayer)
        mixins.push(island.name + temperature.name)
      })
    }
    return mixins.join('、')
  }
  const getPayment = () => {
    let mixins = []
    if (current.paymentTypes) {
      payments.forEach(item => {
        if (current.paymentTypes.includes(item.id)) {
          mixins.push(item.name)
        }
      })
    }
    return mixins.join(' / ')
  }

  const handleChangeAmount = (value) => {
    if (value === true) {
      if (current.remainingQuantity !== null && amount + 1 + getCurrentStock() > current.remainingQuantity) {
        message.error(`目前可購買數量不足，剩餘數量為${current.remainingQuantity}`)
        return
      }
      setAmount(amount + 1)
    } else if (value === false) {
      if (amount > 1) {
        setAmount(amount - 1)
      }
    } else {
      if (current.remainingQuantity !== null && value + getCurrentStock() > current.remainingQuantity) {
        message.error(`目前可購買數量不足，剩餘數量為${current.remainingQuantity}`)
        setAmount(current.remainingQuantity)
        return
      }
      setAmount(value)
    }
  }

  const handleAddProduct = async () => {
    if (current.remainingQuantity !== null && amount + getCurrentStock() > current.remainingQuantity) {
      message.error(`目前可購買數量不足，剩餘數量為${current.remainingQuantity}`)
    } else {
      await addCart(current.id, amount)
    }
    setModalVisible(false)
  }

  useEffect(() => {
    if (!visible) {
      setAmount(1)
    }
  }, [visible])


  const Footer = <div
    className='flex w-full justify-between items-center bg-white px-2 sm:px-8 py-2 sm:py-4 rounded sm:rounded-2xl'
    style={{boxShadow: '0 -2px 10px rgba(0, 0, 0, .08)'}}>
    <InputNumber
      size={'large'}
      controls={false}
      className='w-16 sm:w-24 h-10 sm:h-12 text-center'
      addonBefore={<div className='cursor-pointer flex justify-center items-center rounded sm:rounded-lg'>
        <MinusOutlined onClick={() => handleChangeAmount(false)}/>
      </div>}
      addonAfter={<div className='cursor-pointer flex justify-center items-center rounded sm:rounded-lg'>
        <PlusOutlined onClick={() => handleChangeAmount(true)}/>
      </div>}
      min={1}
      defaultValue={amount}
      value={amount}
      onChange={value => handleChangeAmount(value)}
    />
    <Button type="primary" className='w-36 sm:w-44 text-sm sm:text-base' size="large"
            onClick={handleAddProduct}>加入購物車</Button>
  </div>

  return <Modal
    open={visible}
    onCancel={() => setModalVisible(false)}
    footer={null}
    bodyStyle={{padding: 0}}
    closeIcon={<img src={ModalClose} alt=""/>}
    width={640}
  >
    <div className="pt-14">
      <div className="px-4 sm:px-8 overflow-y-auto scroll-style-modal" style={{height: window.innerHeight - 330}}>
        {current ? <div>
          <div>
            <Swiper pagination={true} spaceBetween={50} slidesPerView={1} autoplay={{
              "delay": 2500, "disableOnInteraction": false
            }}>
              {current.productIntroductionPicIds.map(item => {
                return <SwiperSlide key={item.id}>
                  <div className="w-full flex justify-center items-center">
                    <img className={'w-full'} src={`/api/file/pic/${item.id}`} alt=""/>
                  </div>
                </SwiperSlide>
              })}
            </Swiper>
          </div>
          <div className='font-bold text-base sm:text-xl mt-3 sm:mt-4'>
            {current.name}
          </div>
          <div className='flex mt-1 sm:mt-2 text-sm sm:text-base'>
            <div className='text-gray1 line-through mr-2'>${thousandsparator(current.suggestedPrice)}</div>
            <div className="text-primary ">${thousandsparator(current.preferentialPrice)}</div>
          </div>
          <div className='w-full h-0.5 my-3 sm:my-4 bg-gray5'/>
          <div className='flex flex-col text-sm sm:text-base justify-center'>
            <div className='flex items-center'>
              <img className='mr-2 sm:mr-4 w-5 h-5 sm:w-6 sm:h-6' src={cardImg} alt=""/>
              <div className='flex-1'>{getPayment()}</div>
            </div>
            <div className='flex items-center mt-2'>
              <img className='mr-2 sm:mr-4 w-5 h-5 sm:w-6 sm:h-6' src={transportImg} alt=""/>
              <div className='flex-1'>{getShipping()}</div>
            </div>
            {
              !!current.tickets?.length &&
              <div className="flex flex-col mt-3 sm:mt-4 p-3 sm:p-4 bg-gray7 rounded sm:rounded-lg">
                {current.tickets.map((item, index) => {
                  return (
                    <div className={'text-sm sm:text-base'} key={`ticket-${index}`}>
                      單筆消費滿
                      <span
                        className="text-blue px-1 sm:px-2 text-sm sm:text-lg">{item.targetProductNum ? item.targetProductNum : item.targetAmount}</span>
                      {item.targetAmount ? '元' : '份'}，將贈送
                      <span className="text-blue px-1 sm:px-2 text-sm sm:text-lg">{item.ticketName}</span>
                    </div>
                  )
                })}
              </div>
            }
            <Tabs defaultActiveKey="1" size={'large'} className={'mt-2 mb-4'}>
              <TabPane tab="商品簡介" key="1">
                <div className={'text-xs sm:text-base'} dangerouslySetInnerHTML={{__html: current.introduction}}/>
              </TabPane>
              <TabPane tab="商品規格" key="2">
                <div className={'text-xs sm:text-base'} dangerouslySetInnerHTML={{__html: current.specification}}/>
              </TabPane>
              <TabPane tab="注意事項" key="3">
                <div className={'text-xs sm:text-base'} dangerouslySetInnerHTML={{__html: current.precaution}}/>
              </TabPane>
            </Tabs>
          </div>
        </div> : <div/>
        }
      </div>
      {Footer}
    </div>
  </Modal>
}

export default React.memo(ProductDetail)
