import { createContext, useContextSelector } from 'use-context-selector'
import { useCallback, useEffect, useState } from 'react'
import useLocalStorage from '@/hook/useLocalStorage'
import { useHttp } from '@/pages/Promote/core/hooks/useHttp'
import { useParams } from 'react-router-dom'
import { message } from 'antd'
import temperature from '@/const/temperature.json'
import { useLoading } from '@/hook/useLoading'
import { useMitt } from 'react-mitt'

const context = createContext(null)

const initialCart = {
  products: [],
  totalProductAmount: 0,
  accumulativePoints: 0,
}

export const AuthProvider = ({ children }) => {
  const { setLoading } = useLoading()
  const { identifier } = useParams()
  const { cartApis, userApis } = useHttp()
  const [auth, setAuth] = useState(null)
  const [token, setToken] = useLocalStorage(`${process.env.REACT_APP_PROJECT_NAME}_token`, '')
  const [cart, setCart] = useState(initialCart)
  const [rememberMe, setRememberMe] = useState(null)
  const [rakeBackPoint, setRakeBackPoint] = useState(0)
  const { emitter } = useMitt()
  const refreshCart = useCallback(async () => {
    if (token && identifier) {
      const res = await cartApis.list(identifier)
      if (res.success) {
        const { data } = res
        // TODO ??? 太大費周章了吧
        data.products.forEach(item => {
          item.temperatureName = temperature.find(
            temperatureItem => temperatureItem.id === item.temperatureLayer
          ).name
        })
        /// TODO ??? 太大費周章了吧2
        data.total = data.products.reduce((prev, item) => {
          return (
            prev +
            item.products.reduce((p, i) => {
              return p + i.quantity
            }, 0)
          )
        }, 0)
        setCart(data)
      } else {
        setCart(initialCart)
      }
    }
  }, [identifier, token])

  const getUserInfo = useCallback(async () => {
    if (token && !auth) {
      const res = await userApis.userInfo()
      if (res.success) {
        setAuth(res.data)
        setRakeBackPoint(res.data.memberRankResponse.rakeBackPoint)
      }
    }
  }, [auth, token])

  const useDistributorExistsCallback = (callback) => (...args) => {
    if (identifier) {
      return callback?.(...args)
    } else {
      message.error('加入購物車失敗，請確認分銷商是否存在')
    }
  }

  const addCart = useDistributorExistsCallback(async (productId, quantity) => {
    try {
      setLoading(true)
      const res = await cartApis.add({ productId, quantity, distributorIdentifier: identifier })
      if (res.success) {
        setLoading(false)
        message.success('添加成功')
        refreshCart()
        return Promise.resolve()
      }
    } finally {
      setLoading(false)
    }
  })

  const updateCart = useDistributorExistsCallback(async (cartId, quantity) => {
    try {
      setLoading(true)
      const res = await cartApis.update({ id: cartId, quantity })
      await refreshCart()
      return res.success
    }catch (e) {
      return false
    } finally {
      setLoading(false)
    }
  })

  const deleteCart = useDistributorExistsCallback(async (cartId) => {
    try {
      setLoading(true)
      const res = await cartApis.delete(cartId)
      if (res.success) {
        setLoading(false)
        message.success('刪除成功')
        // TODO ??? 太大費周章了吧3
        await refreshCart()
      }
    } finally {
      setLoading(false)
    }
  }, [refreshCart])

  const logout = () => {
    setAuth(null)
    setToken('')
    setCart(initialCart)
  }

  const bootstrap = () => Promise.all([getUserInfo(), refreshCart()])

  useEffect(()=>{
    ;(async ()=>{
      await bootstrap()
    })()

    emitter.on('LOG_OUT', async () => {
      logout()
    })

  }, [token])

  return <context.Provider value={{
    rememberMe,
    setRememberMe,
    auth,
    setAuth,
    token,
    setToken,
    cart,
    refreshCart,
    addCart,
    updateCart,
    deleteCart,
    logout,
    rakeBackPoint
  }}>{children}</context.Provider>
}

export const useAuth = (selector) => {
  return useContextSelector(context, selector)
}
