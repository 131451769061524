import { useCallback, useEffect } from 'react'
import { useSafeState } from '@/hook/useSafeState'

export const useDetailLoading = () => {
	const [loading, setLoading] = useSafeState(false)
	const [confirmLoading, setConfirmLoading] = useSafeState(false)

	const callConfirmFunc = useCallback(
		callback =>
			async (...args) => {
				setConfirmLoading(true)
				try {
					await callback(...args)
					setConfirmLoading(false)
				} catch (error) {
					console.error(error)
					setConfirmLoading(false)
				}
			},
		[setConfirmLoading],
	)

	const useConfirm = callback => callConfirmFunc(callback)

	const useConfirmCallback = (callback, deps = []) =>
		useCallback(callConfirmFunc(callback), [callConfirmFunc].concat(deps))

	const useLoadingEffect = useCallback(
		(callback, deps = []) => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			return useEffect(() => {
				;(async () => {
					setLoading(true)
					try {
						await callback()
						setLoading(false)
					} catch (error) {
						console.error(error)
						setLoading(false)
					}
				})()
			}, deps)
		},
		[setLoading],
	)

	return {
		loading,
		confirmLoading,
		useConfirm,
		useConfirmCallback,
		useLoadingEffect,
	}
}
