import {Checkbox, Input, Radio} from 'antd'
import PromoteStyle from '@/styles/Promote.module.scss'
import {CardBody, CardHead} from "@/pages/Checkout/components/card";

const Invoice = ({ data, setInvoice, defaultCarrier }) => {
  const handleSetValue = (key, value) => {
    let obj = { ...data }
    obj[key] = value
    setInvoice(obj)
  }

  const handleSelectType = (value) => {

    setInvoice({
      ...data,
      invoiceType: value,
      invoiceCarrierType: null,
      isAgreeRecordCarrier: null,
      carrier: null,
      uniformNo: null,
      companyName: null,
      donationUnit: null,
      invoiceAddress: null,
    })
  }
  const handleCheckedCarrier = (e) => {
    const checked = e.target.value
    const invoiceData = {
      ...data,
      carrier: '',
      // isAgreeRecordCarrier: false,
    }
    if (checked === 'MEMBER_CARRIER') {
      invoiceData.invoiceType = 'MEMBER_CARRIER'
      invoiceData.invoiceCarrierType = null
    } else {
      invoiceData.invoiceType = 'PERSONAL_CLOUD_INVOICE'
      invoiceData.invoiceCarrierType = e.target.value
      if (defaultCarrier) {
        const val = defaultCarrier.find(item => item.type === e.target.value)
        if (val) {
          invoiceData.carrier = `/${val.carrier}`
        }
      }
    }
    setInvoice(invoiceData)
  }
  return <div className='mt-3 sm:mt-6 relative w-full-half max-w-common-wrap'>
    <CardHead title={'發票資料'}>
      <Radio
        className={'text-sm sm:text-base mb-3 sm:mb-0'}
        value={'PERSONAL_CLOUD_INVOICE'}
        onChange={(e) => handleSelectType(e.target.value)}
        checked={data.invoiceType === 'PERSONAL_CLOUD_INVOICE' || data.invoiceType === "MEMBER_CARRIER"}>
        個人雲端發票
      </Radio>
      <Radio
        className={'text-sm sm:text-base mb-3 sm:mb-0'}
        value={'COMPANY_ONLINE_PRINT'}
        onChange={(e) => handleSelectType(e.target.value)}
        checked={data.invoiceType === 'COMPANY_ONLINE_PRINT'} >
        公司用(線上列印)
      </Radio>
      <Radio
        className={'text-sm sm:text-base'}
        value={'CLOUD_INVOICE_DONATION'}
        onChange={(e) => handleSelectType(e.target.value)}
        checked={data.invoiceType === 'CLOUD_INVOICE_DONATION'}>
        雲端發票捐贈
      </Radio>
    </CardHead>
    <CardBody>
      {(data.invoiceType === 'PERSONAL_CLOUD_INVOICE' || data.invoiceType === "MEMBER_CARRIER") && <>
        <Radio
          value='MEMBER_CARRIER'
          onChange={handleCheckedCarrier}
          checked={data.invoiceType === 'MEMBER_CARRIER'}
          className='text-sm sm:text-base mb-2 block'>
          存到會員載具(自行兌獎)
        </Radio>
        <Radio
          value='MOBILE_PHONE_BARCODE_CARRIER'
          onChange={handleCheckedCarrier}
          checked={data.invoiceCarrierType === 'MOBILE_PHONE_BARCODE_CARRIER'}
          className='text-sm sm:text-base mb-2 block'>
          手機條碼載具
        </Radio>
        {data.invoiceCarrierType === 'MOBILE_PHONE_BARCODE_CARRIER' &&
          <Input className='mb-2' value={data.carrier || ''}
            placeholder={'請輸入" / "後大寫英數字7碼'}
            onChange={e => {
              setInvoice({ ...data, carrier: e.target.value })
            }}/>
        }
        {data.invoiceCarrierType === 'MOBILE_PHONE_BARCODE_CARRIER' &&<Checkbox className='text-xs mb-2'
                                                                                       checked={data.isAgreeRecordCarrier}
                                                                                       onChange={(e) => { setInvoice({ ...data, isAgreeRecordCarrier: e.target.checked }) }}>
          我同意紀錄本次手機條碼為常用載具
        </Checkbox>}
        <Radio
          onChange={handleCheckedCarrier}
          value='CITIZEN_DIGITAL_CERTIFICATE_CARRIER'
          checked={data.invoiceCarrierType === 'CITIZEN_DIGITAL_CERTIFICATE_CARRIER'}
          className='text-sm sm:text-base mb-2 block'>
          自然人憑證載具
        </Radio>
        {data.invoiceCarrierType === 'CITIZEN_DIGITAL_CERTIFICATE_CARRIER' &&
          <Input
            className='mb-2'
            placeholder='共16碼大寫英數字'
            value={data.carrier || ''}
            onChange={e => {
              setInvoice({ ...data, carrier: e.target.value })
            }}/>
        }
        {data.invoiceCarrierType === 'CITIZEN_DIGITAL_CERTIFICATE_CARRIER' &&<Checkbox className='text-xs mb-2'
          checked={data.isAgreeRecordCarrier}
          onChange={(e) => { setInvoice({ ...data, isAgreeRecordCarrier: e.target.checked }) }}>
          我同意紀錄本次自然人憑證為常用載具
        </Checkbox>}
      </>}
      {
        data.invoiceType === 'COMPANY_ONLINE_PRINT' && <>
          <Radio className='mb-2 block' checked>公司用(線上列印)</Radio>
          <Input className='mb-2' value={data.uniformNo} onChange={e => handleSetValue('uniformNo', e.target.value)} placeholder='請輸入統一編號'/>
          <Input className='mb-2' value={data.companyName} onChange={e => handleSetValue('companyName', e.target.value)} placeholder='請輸入公司名稱'/>
        </>
      }
      {
        data.invoiceType === 'CLOUD_INVOICE_DONATION' && <>
          <Radio className='mb-2 block' checked>雲端發票捐贈</Radio>
          <Input className='mb-2' value={data.donationUnit} onChange={e => handleSetValue('donationUnit', e.target.value)} placeholder='請輸入愛心捐贈碼'/>
        </>
      }
      <div
        className={`text-xs sm:text-base mb-2 ${data.invoiceType === 'PERSONAL_CLOUD_INVOICE' || data.invoiceType === "MEMBER_CARRIER" ? 'sm:ml-7 sm:pl-0.5' : ''}`}>
        ※ 依法規定，個人發票一經開立，不得更改或改開公司戶發票。
        <span className={`text-xs sm:text-base ${PromoteStyle.link}`} onClick={()=>window.open('https://www.einvoice.nat.gov.tw/')}>
          財政部電子發票流程說明
        </span>
      </div>
    </CardBody>
  </div>
}

export default Invoice
