import Style from "@/styles/Checkout.module.scss";

export const CardHead = ({className, children, title}) => {
  return <div className={`${Style.card__head} bg-gray5 pt-2 pb-4 sm:py-4 px-3 sm:px-6 border-t-1 sm:border-t-2 border-x-1 sm:border-x-2 rounded-t-lg${className ? ' ' + className : ''}`}>
    <div className={`${Style.card__title} mr-8 text-sm sm:text-xl font-bold`}>{title}</div>
    {children}
  </div>
}

export const CardBody = ({className, children}) => {
  return <div className={`border-b-1 sm:border-b-2 border-x-1 sm:border-x-2 border-gray5 rounded-b-lg px-3 py-4 sm:px-4${className ? ' ' + className : ''}`}>
    {children}
  </div>
}
