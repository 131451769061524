import Logo from '@/images/logo.svg'
import Step from './components/Step'
import Stepper from './components/Stepper'
import {Collapse, Modal} from 'antd'
import {Link, useHistory} from 'react-router-dom'
import {getImageLink, thousandsparator, useIdentifierPath} from '@/pages/Promote/core/helper'
import {useAuth} from '@/pages/Promote/core/hooks/useAuth'
import {useEffect, useMemo} from 'react'
import {useLoading} from '@/hook/useLoading'
import ashbin from '@/images/ashbin.png'
import { computedPoint } from '@/lib/helper'

const {Panel} = Collapse
const Cart = () => {
  const history = useHistory()
  const {setLoading} = useLoading()
  const {getIdentifierPath} = useIdentifierPath()
  const rakeBackPoint = useAuth(e=>e.rakeBackPoint)
  const cart = useAuth(e => e.cart)
  const refreshCart = useAuth(e => e.refreshCart)
  const updateCart = useAuth(e => e.updateCart)
  const deleteCart = useAuth(e => e.deleteCart)
  const disabledCheckout = useMemo(() => {
    const hasProductsLength = cart.products.length > 0
    let hasAnyTakeDownProduct = false
    if (hasProductsLength) {
      for (let i = 0; i < cart.products.length; i++) {
        let isBreak = false
        const products = cart.products[i].products
        for (let j = 0; j < products.length; j++) {
          const e = products[j]
          if (e.productStatus === 'DISTRIBUTOR_PRODUCT_REMOVED' || e.productStatus === 'PRODUCT_REMOVED') {
            isBreak = true
            break
          }
        }
        if (isBreak) {
          hasAnyTakeDownProduct = true
          break
        }
      }
    }
    return !hasProductsLength || hasAnyTakeDownProduct
  }, [cart.products])
  const handleQuantityChange = async (cartId, quantity) => {
    return await updateCart(cartId, quantity)
  }
  const handleDeleteItem = (cartId) => {
    Modal.confirm({
      title: '刪除確認',
      async onOk() {
        await deleteCart(cartId)
      }
    })
  }

  const handleCheckout = () => {
    if (!disabledCheckout) {
      history.push(getIdentifierPath('Checkout', true))
    }
  }

  useEffect(async () => {
    try {
      setLoading(true)
      await refreshCart()
    } finally {
      setLoading(false)
    }
  }, [])
  return (
    <div className={`w-full h-full flex flex-col justify-center items-center pb-10 sm:pb-28 pt-10 sm:pt-20`}>
      <div>
        <img src={Logo} alt="" className={'w-32 sm:w-44'} />
      </div>
      <div className="text-sm sm:text-xl my-3 sm:my-4">購物車</div>
      <Step step={"1"}/>
      {cart.products.map((item, index) => {
        return (
          <Collapse key={`cart-${index}`} defaultActiveKey={['1']}
                    className={'w-full-half max-w-common-wrap mb-3 sm:mb-6 rounded-lg overflow-hidden border-0 '}
          >
            <Panel className={'custom-ant-collapse-item'} showArrow={false} header={item.temperatureName} key="1">
              <div className={'mt-1 sm:mt-0'}>
                {item.products.map((product, pIndex) => {
                  return <div key={`cartItem-${index}-${pIndex}`}>
                    {pIndex > 0 ? <div className={'px-3 sm:px-6'}>
                      <div className={'border-b-1 sm:border-b-2 border-gray5 w-full'} />
                    </div> : null}
                    <div className='flex sm:items-center p-3 sm:p-6'>
                      <img src={getImageLink(product.coverId)} className={'w-25 rounded md:rounded-none cart-image-size'} alt=""/>
                      <div className={'flex items-center flex-1 ml-3 md:ml-8 flex-col md:flex-row relative'}>
                        <div className='text-sm md:text-lg	text-left w-full md:w-max md:flex-1'>
                          {product.name}
                        </div>
                        <div className={'flex items-start sm:items-center flex-col sm:flex-row w-full flex-1 sm:flex-none md:w-max ml-0 md:ml-auto'}>
                          {product.productStatus === 'DISTRIBUTOR_PRODUCT_REMOVED' || product.productStatus === 'PRODUCT_REMOVED' ? <div className='pr-8 text-xs md:text-base text-primary'>已下架</div> : <div className='pr-8 text-xs md:text-base'>
                            <span className='mr-2 text-gray1 line-through	'>${product.originalPrice}</span>
                            <span className='text-primary'>${product.price}</span>
                          </div>}
                          <Stepper
                            disabled={product.productStatus === 'DISTRIBUTOR_PRODUCT_REMOVED' || product.productStatus === 'PRODUCT_REMOVED'}
                            className={'mt-auto sm:mt-0'}
                            value={product.quantity} onChange={quantity => handleQuantityChange(product.id, quantity)}
                          />
                          <img src={ashbin} alt={'ashbin'} className={'cursor-pointer md:ml-8 absolute right-0 sm:right-0 -top-0.5 sm:top-0 md:relative w-5 h-5 sm:w-6 sm:h-6'}
                               onClick={() => handleDeleteItem(product.id)}/>
                        </div>
                      </div>
                    </div>
                  </div>
                })}
              </div>
              <div
                className={`flex flex-col sm:flex-row justify-end items-end text-xs sm:text-base sm:items-baseline px-0 sm:px-3 py-2 sm:py-4 sm:px-6 sm:py-3 border-t-1 sm:border-t-2 border-gray5`}>
                {item.subtotalProductAmount >= item.shippingFreeStandard ?
                  <div className={'pr-2 sm:pr-0'}>免運標準：<span className={'mx-2'}>{thousandsparator(item.shippingFreeStandard)}</span>元，已達免運
                  </div> :
                  <div className={'pr-2 sm:pr-0'}>免運標準: <span
                    className='font-bold mx-2'>{thousandsparator(item.shippingFreeStandard)}</span>元，還差<span
                    className='text-primary mx-2 font-bold'>{thousandsparator(item.shippingFreeStandard - item.subtotalProductAmount)}</span>元
                  </div>}
                <div className={'sm:hidden border-b-1 border-gray5 w-full mt-2 sm:mt-0'} />
                <div className={'sm:ml-auto pr-2 sm:pr-0 pt-2 sm:pt-0'}>小計：<span
                  className={'mx-2 text-base sm:text-xl font-bold'}>{thousandsparator(item.subtotalProductAmount)}</span>元
                </div>
              </div>
            </Panel>
          </Collapse>
        )
      })}
      <div className='border-gray5 border-1 sm:border-2 flex flex-col rounded-lg w-full-half max-w-common-wrap'>
        <div className="flex-1 flex flex-col px-3 py-3 sm:py-4 sm:px-6 ">
          {
            cart.tickets?.map((item, index) => {
              return (
                <div key={`ticket-${index}`} className='mb-2 text-sm sm:text-base last:mb-0'>
                  單筆消費滿
                  <span
                    className="text-blue text-sm sm:text-lg px-2">{item.targetProductNum ? item.targetProductNum : item.targetAmount}</span>
                  {item.targetAmount ? '元' : '份'}，將贈送
                  <span className="text-blue text-sm sm:text-lg px-2">{item.ticketName}</span>
                </div>
              )
            })
          }
        </div>
        <div
          className={`border-t flex justify-end items-end flex-wrap flex-col sm:px-6 sm:py-4 text-xs sm:text-base`}>
          <span className={'pt-2 sm:pt-0 pr-2 sm:pr-0'}>本次累積點數：<span
            className='text-primary font-bold mx-2 text-base sm:text-xl'>{cart.totalProductAmount>100?computedPoint(rakeBackPoint):computedPoint(cart.accumulativePoints)}</span>點</span>
          <div className={'sm:hidden border-b-1 border-gray5 w-full mt-2 sm:mt-0'} />
          <span className={'ml-auto pr-2 sm:pr-0 py-2 sm:py-0'}>小計：<span
            className='text-primary text-base sm:text-xl font-bold mx-2'>{thousandsparator(cart.totalProductAmount)}</span>元</span>
        </div>
      </div>
      <div className='mt-10 flex flex-col sm:flex-row justify-center w-full-half max-w-common-wrap'>
        <Link
          className={`px-6 sm:px-16 py-3 rounded sm:rounded-lg border bg-gray2 text-white text-sm sm:text-base sm:mr-2 cursor-pointer text-center`}
          to={getIdentifierPath()}>繼續購物</Link>
        <div
          className={`px-6 sm:px-16 py-3 rounded sm:rounded-lg border text-white text-sm sm:text-base mt-2 sm:mt-0 sm:ml-2 cursor-pointer text-center ${disabledCheckout ? 'bg-red-100' : 'bg-primary'}`}
          onClick={handleCheckout}>去結帳{cart.total ? `(${cart.total})` : ''}</div>
      </div>
    </div>
  )
}

export default Cart
