import {Modal, Button} from 'antd'
import {useState, useEffect} from 'react'
import {useHttp} from '@/pages/Promote/core/hooks/useHttp'
import ModalClose from '@/images/promote/modal_close.png'

const PrivacyModal = ({visible, setModalVisible, onOK}) => {
  const [text, setText] = useState('')
  const {userApis} = useHttp()
  const fetchData = async () => {
    const result = await userApis.servicesAndPrivacy()
    if (result.success) {
      setText(result.data.content)
    }
  }
  useEffect(() => {
    fetchData()
  }, []);

  return (
    <Modal closeIcon={<img src={ModalClose} alt=""/>} width={640} bodyStyle={{padding: '24px 4px 24px 4px'}}
           onCancel={() => {
             setModalVisible(false)
           }} open={visible} title="會員服務及隱私權相關條款" footer={null}>
      <div className='overflow-y-auto pr-4 scroll-style-modal text-sm px-3  sm:text-base sm:px-7 sm:pb-4'
           style={{height: window.innerHeight - 330}} dangerouslySetInnerHTML={{__html: text}}>
      </div>
      <div className='flex justify-center mt-6'>
        <Button className={'rounded-lg leading-none px-6 h-9 text-sm sm:text-base'} type="primary" onClick={() => {
          onOK()
          setModalVisible(false)
        }}>我已閱讀並同意條款</Button>
      </div>
    </Modal>
  )
}

export default PrivacyModal
