import Home from '../pages/Home'
import PromoteLayout from '../pages/Promote/index.jsx'
import PromoteHome from '../pages/Promote/Home.jsx'
import PromoteRegister from '../pages/Register/index.jsx'
import PromoteLogin from '../pages/Login.jsx'
import PromoteCart from '../pages/Cart/index.jsx'
import PromoteCheckout from '../pages/Checkout/index.jsx'
import PromoteOrder from '../pages/Order.jsx'
import ForgetPassword from '../pages/Register/ForgetPassword/index.jsx'
const routes = [
  {
    name: '推廣',
    path: '/d/:identifier',
    component: PromoteLayout,
    routes:[
      {
        path: '/d/:identifier/Register',
        component: PromoteRegister
      },
      {
        path: '/d/:identifier/Login',
        component: PromoteLogin
      },
      {
        path: '/d/:identifier/Cart',
        component: PromoteCart
      },
      {
        path: '/d/:identifier/Checkout',
        component: PromoteCheckout
      },
      {
        path: '/d/:identifier/Order',
        component: PromoteOrder
      },
      {
        path: '/d/:identifier/ForgetPassword',
        component: ForgetPassword
      },
      {
        component: PromoteHome
      }
    ]
  },
  {
    name: '首頁',
    path: '/',
    exact: true,
    component: Home,
  }
]
export default routes
