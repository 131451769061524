import Step from './Cart/components/Step'
import Logo from '@/images/logo.svg'
import {CheckCircleFilled, WarningFilled ,CloseCircleFilled} from '@ant-design/icons'
import {Alert, message} from 'antd'
import {Link} from 'react-router-dom'
import {useIdentifierPath} from '@/pages/Promote/core/helper'
import {useLocation} from 'react-router-dom'
import {useEffect, useState, useMemo} from 'react'
import {thousandsparator} from '@/pages/Promote/core/helper'
import invoice from '@/const/invoice.json'
import {useAuth} from '@/pages/Promote/core/hooks/useAuth'
import {useLoading} from '@/hook/useLoading'
import {useHttp} from "@/pages/Promote/core/hooks/useHttp";
import CopyIcon from '@/images/copy.png'
import copy from "copy-to-clipboard";

const Order = () => {
  const {setLoading} = useLoading()
  const [order, setOrder] = useState(null)
  const location = useLocation()
  const { checkoutApis } = useHttp()
  const refreshCart = useAuth(e => e.refreshCart)
  const getInvoice = useMemo(() => {
    if (order && order.invoiceType) {
      return invoice.find(item => item.id == order.invoiceType).name
    }
    return ''
  }, [order])
  useEffect(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('orderId');
    const oredrId = location?.query?.id || myParam
    if (oredrId) {
      try {
        setLoading(true)
        await refreshCart()
        await getOrder(oredrId)
      } finally {
        setLoading(false)
      }

    }
  }, [])


  const getOrder = async (orderId) =>{
    const result = await checkoutApis.completionInfo({
      id: orderId
    })
    if (result.success) {
      setOrder(result.data)
    }
  }
  const {getIdentifierPath} = useIdentifierPath()

  const handleCopy = () => {
    if(copy(order?.paymentAccount)){
      message.success('複製成功!')
    }else message.error("複製失敗，請手動複製");
  }

  return <div
    className={`w-full-half max-w-common-wrap flex flex-col justify-center items-center mx-auto pb-10 sm:pb-28 pt-10 sm:pt-20 text-xs sm:text-base`}>
    <div>
      <img src={Logo} alt="" className={'h-8.5 sm:h-10'}/>
    </div>
    <div className="text-base sm:text-xl my-4">購物車</div>
    <Step step='3'/>
    <div className='flex flex-col items-center mb-4 sm:mb-10'>
      {
        order?.status === "FAIL" ? (
          <>
            <CloseCircleFilled  className={'text-6xl sm:text-6xl text-primary'}/>
            <span className='text-base sm:text-xl font-bold mt-2 mb-4'>送出失敗</span>
            <div className='text-center'>付款交易失敗，請重新進行購物</div>
          </>
        ):(
          <>
            <CheckCircleFilled className={'text-6xl sm:text-6xl text-green'}/>
            <div className='text-base sm:text-xl font-bold mt-2 mb-4'>完成訂單</div>
            <div className={'mb-2 sm:mb-0 text-sm sm:text-base'}>
              您已完成本筆交易的訂購程序，將依據訂單順序安排進行配送，感謝您的訂購。
            </div>
            <div>※ 因商品寄送方式不同，可能會分批抵達。</div>
          </>
        )
      }


    </div>
    {order && order?.status !== "FAIL" &&
      <div className='w-full relative'>
        {
          order.paymentType === "ATM_TRANSFER"  ?(
            <>
              <div
                className="flex items-center py-2 sm:py-4 px-3 sm:px-6 border border-gray5 rounded-t sm:rounded-t-lg text-sm sm:text-xl font-bold">
                ATM資訊
              </div>
              <div
                className='border-b border-x border-gray5 rounded-b sm:rounded-b-lg py-2 sm:py-4 px-3 sm:px-6 font-bold'>
                <div className='mb-2'>繳款銀行：<span className='font-normal ml-2'>{order.paymentBankCode} {order.paymentBank}</span></div>
                <div className='mb-2 flex items-center'>繳費帳號：
                  <span className='font-normal ml-2 flex items-center'>
                    {order.paymentAccount}
                    <div onClick={(e)=>{
                      handleCopy(e)
                    }}>
                    <img
                      className='object-contain ml-1 '
                      src={CopyIcon}
                      width="15" height="15"
                    />
                  </div>
                  </span>
                  </div>
                <div className='mb-2'>
                  <span>繳費金額：</span>
                  <span className='text-primary mx-2'>{thousandsparator(order.amount)}</span>元
                </div>
                <div className='mb-2'>繳費期限：<span
                  className='font-normal ml-2'>{order.paymentDeadLine}</span></div>
                <div className='mb-2'>以上繳費資訊限本次交易使用，逾期無效自動取消訂單</div>
              </div>
            </>
          ):null
        }
        <div
          className="flex items-center py-2 sm:py-4 px-3 sm:px-6 border border-gray5 rounded-t sm:rounded-t-lg text-sm sm:text-xl font-bold">
          訂購資料
        </div>
        <div
          className='border-b border-x border-gray5 rounded-b sm:rounded-b-lg py-2 sm:py-4 px-3 sm:px-6 font-bold'>
          <div className='mb-2'>訂單編號：<span className='font-normal ml-2'>{order.orderNo}</span></div>
          <div className='mb-2'>訂單日期：<span className='font-normal ml-2'>{order.createDate}</span></div>
          <div className='mb-2'>
            <span>訂單金額：</span>
            <span className='text-primary mx-2'>{thousandsparator(order.amount)}</span>元
          </div>
          <div className='mb-2'>付款方式：<span
            className='font-normal ml-2'>{order.paymentType === "CREDIT_CARD" ? '信用卡' : 'ATM'}</span></div>
          <div className='mb-2'>發票資料：<span className='font-normal ml-2'>{getInvoice}</span></div>
        </div>
      </div>
    }
    <div className='w-full my-4 p-4 bg-primary bg-opacity-10 rounded-lg flex items-baseline'
         style={{color: '#ff0000'}}>
      <WarningFilled className='pr-2 text-base sm:text-xl'/>
      {'知足饗樂不會以電話或簡訊通知變更付款，或要求以ATM進行任何操作，請定期更改密碼確保帳戶安全。'}
    </div>
    <div className='w-full'>如需查詢訂單進度，請牢記您的訂單編號，並連絡客服電話處理：0800-123-123</div>
    <Link className='w-full sm:w-auto text-center mt-5 sm:mt-10 sm:px-20 py-3 sm:py-4 rounded sm:rounded-lg bg-gray2 text-white cursor-pointer text-sm sm:text-base'
          to={getIdentifierPath('/')}>回首頁</Link>
  </div>
}

export default Order
