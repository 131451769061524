import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import React, {useEffect, useRef, useState} from 'react'

const Stepper = ({ className, value, onChange, disabled = false }) => {
  const [state,setState] = useState(0)
  const focusStateRef = useRef(state)

  const handleChange = async (minus)=>{
    if(minus && state <= 1)
      return
    const quantity = minus ? state-1 : state+1
    const check = await onChange(quantity)
    if( check ){
      setState(quantity)
    }
  }

  const handleBlur = async () => {
    if (state === 0) {
      const resetState = focusStateRef.current || 1
      setState(resetState)
      if (resetState !== focusStateRef.current) {
        const check = await onChange(state)
        if( check ){
          setState(state)
        }else{
          setState(value)
        }
      }
      return
    }
    if (focusStateRef.current !== state) {
      const check = await onChange(state)
      if( check ){
        setState(state)
      }else{
        setState(value)
      }
    }
  }

  useEffect(()=>{
    setState(value)
  }, [value])

  return <div className={'flex items-center border-1 sm:border-2 border-gray5 rounded-lg w-fit' + (className ? ' ' + className : '')}>
    {disabled ? null : <div className={`flex justify-center items-center p-2 cursor-pointer ${state === 1 ? 'text-gray-300':''}`} onClick={()=>handleChange(true)}>
      <MinusOutlined />
    </div>}
    <div className={`${disabled ? '' : 'border-x-1 sm:border-x-2'} px-2 flex justify-center items-center`} style={{height:'30px',width: '50px'}}>
      <input  type="text" onChange={(e)=>setState(Number(e.target.value))} onFocus={() => focusStateRef.current = state} onBlur={handleBlur} value={state} style={{width: '30px',textAlign:'center',outline: 'none'}} inputMode="numeric" />
    </div>
    {disabled ? null : <div className="flex justify-center items-center p-2 cursor-pointer" onClick={()=>handleChange(false)}>
      <PlusOutlined />
    </div>}
  </div>
}

export default React.memo(Stepper)
