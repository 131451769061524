import Logo from '@/images/logo.svg'
import Step from '../../Promote/components/Step'
import UserInfo from '../components/UserInfo'
import SendMessage from '../components/SendMessage'
import {useHttp} from '@/pages/Promote/core/hooks/useHttp'
import {useEffect, useState} from 'react'
import {message} from 'antd'
import {useIdentifierPath} from '@/pages/Promote/core/helper'
import {useHistory, useLocation} from 'react-router-dom'
import {useAuth} from "@/pages/Promote/core/hooks/useAuth";
import ResetPassword from "@/pages/Register/ForgetPassword/ResetPassword";
import ForgetPasswordCheck from "@/pages/Register/ForgetPassword/ForgetPasswordCheck";

const ForgetPassword = () => {
  const [info,setInfo] = useState(null)
  const [verifyError, setVerifyError] = useState(false)
  const [userId , setUserId] = useState(null)
  const {userApis} = useHttp()
  const history = useHistory()
  const {getIdentifierPath} = useIdentifierPath()
  const [step, setStep] = useState(1)


  const handleSaveSendData = (data)=>{
    setInfo(data)
    setStep(2)
  }

  const handleResetPhone = async()=>{

    const res = await userApis.resetVerify(info)
    if (res.success) {
      message.success('重新發送成功!'+res.data)
    }else{
      message.warning(res.message)
    }


  }
  const handleSendMessage = async(verifyCode)=>{

    const res = await userApis.verify({
      verifyCode,
      userPhone : info.phone
    })
    const { message: m , success , data } = res
    if (success) {
      setUserId(data)
      setStep(3)
    }


  }
  const handleUpdatePassword =  async (d) => {
    const res = await userApis.resetPassword({
      newPassword: d.newPassword,
      userId : userId
    })
    const { message: m , success } = res
    if (success) {
      message.success('密碼重設成功！')
      history.push({
        pathname: getIdentifierPath() + '/Login',
      })
    }else{
      message.warning(m)
    }
  }
  const handlePhone = () =>{
    setStep(1)
  }






  return (
    <div className="w-full h-full flex flex-col justify-center items-center px-4 pt-10 pb-28 sm:pt-20 sm:pb-24">
      <div >
        <img className={'h-8.5 sm:h-auto'} src={Logo} alt="logo" />
      </div>
      <div className="mt-2 mb-4 text-sm sm:my-4 sm:text-xl">忘記密碼</div>
      <Step step={step} forgetPassword={true}/>
      {step === 1 ?
        <ResetPassword nextStep={(data) => handleSaveSendData(data)}/>
        :
       step === 2 ?
         <SendMessage forgetPassword handlePhone={handlePhone} registerInfo={info}  handleSendMessage={handleSendMessage} handleResetPhone={handleResetPhone} hasError={verifyError}  />
        : <ForgetPasswordCheck  handleUpdatePassword={handleUpdatePassword}/>
      }
    </div>
  )
}

export default ForgetPassword
