import Styles from '@/styles/Loading.module.scss'
import { useLoading } from '@/hook/useLoading'
import { Spin } from 'antd'
import { CSSTransition } from 'react-transition-group'
const Loading = () => {
  const { loading } = useLoading()
  return <CSSTransition unmountOnExit in={loading} timeout={1000} classNames="loading-transition">
    <div className={Styles.loading}>
      <Spin tip="Loading..." size="large"></Spin>
    </div>
  </CSSTransition>
}

export default Loading 