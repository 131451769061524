import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from '@/pages/Promote/core/hooks/useAuth'

export const getImageLink = id =>
  `${process.env.REACT_APP_API_BASE_URL}/file/pic/${id}`

export const useIdentifierPath = () => {
  const { identifier } = useParams()
  const auth = useAuth(e => e.auth)
  const getIdentifierPath = useCallback((suffixPath = '', checkAuth = false) => {
    const prefixPath = `/d/${identifier}`
    if (checkAuth && auth == null) return prefixPath + '/Login'
    return suffixPath ?
      prefixPath + (/^\/+/.test(suffixPath) ? suffixPath : `/${suffixPath}`) :
      prefixPath
  }, [identifier, auth])

  return {
    getIdentifierPath
  }
}

export function thousandsparator(number) {
  try {
    if (number && String(number).replace) {
      return String(number).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
    }
    return number
  } catch (e) {
    return number
  }
}
