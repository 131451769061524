import React, {useEffect, useState} from 'react'
import {thousandsparator} from '@/pages/Promote/core/helper'
import {useAuth} from '@/pages/Promote/core/hooks/useAuth'
import {CardBody, CardHead} from "@/pages/Checkout/components/card";

const CheckoutDetail = ({ isOutlying, logisticData, handleShippingFee,orderTotal }) => {
  const [logistic, setLogistic] = useState({})
  const cart = useAuth(e => e.cart)
  useEffect(() => {
    let obj = {}
    if (isOutlying && (cart.products && cart.products.length > 0) && logisticData.length > 0) {
      cart.products.forEach(item => {
        const { shippingFreeStandard, subtotalProductAmount } = item
        obj[item.temperatureLayer] = {
          shippingFreeStandard,
          subtotalProductAmount
        }
        if (shippingFreeStandard > subtotalProductAmount) {
          const shipping = logisticData.find(shipping => {
            return item.temperatureLayer === shipping.temperatureLayer
          })
          if (shipping) {
            const fee = shipping.shippingFees.find(island => {
              return island.islandType === isOutlying
            })
            obj[item.temperatureLayer].shippingFee = fee.shippingFee
          }
        }
      })
      setLogistic(obj)
      handleShippingFee(obj)
    }else{
      setLogistic({})
    }
  }, [isOutlying,logisticData,cart.products])
  return <div className="mt-3 sm:mt-6 w-full-half max-w-common-wrap">
    <CardHead className={'hidden sm:flex'} title={'訂單摘要'} />
    <CardBody className={'border-t-1 sm:border-t-0'}>
      <div className="flex flex-col sm:flex-row items-center text-xs sm:text-base -my-2 sm:my-0">
        <div className={'flex items-center justify-end sm:justify-start border-b-1 sm:border-b-0 border-gray5 w-full sm:w-fit pb-2 sm:pb-0 mb-2 sm:mb-0'}>
          {logistic.FROZEN &&
            <div className={`mr-4 sm:mr-6`}>
              冷凍運費：
              {logistic.FROZEN.shippingFee ? thousandsparator(logistic.FROZEN.shippingFee) + ' 元' : '免運'}
            </div>
          }
          {logistic.LOW_TEMPERATURE &&
            <div className={`mr-4 sm:mr-6`}>冷藏運費：
              {logistic.LOW_TEMPERATURE.shippingFee ? thousandsparator(logistic.LOW_TEMPERATURE.shippingFee) + ' 元' : '免運'}
            </div>
          }
          {logistic.NORMAL_TEMPERATURE &&<div>常溫運費：{logistic.NORMAL_TEMPERATURE.shippingFee ? thousandsparator(logistic.NORMAL_TEMPERATURE.shippingFee) + ' 元' : '免運'}</div>}
        </div>
        <div className="flex items-center ml-auto">
          <div className={`sm:text-right mr-4 sm:mr-6`}>商品小計<span className="px-1">{cart.totalProductAmount}</span>元</div>
          <div>總計：<span className="text-primary text-base sm:text-lg font-bold px-1">{thousandsparator(orderTotal)}</span>元</div>
        </div>
      </div>
    </CardBody>
  </div>
}

export default React.memo(CheckoutDetail)
