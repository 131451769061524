const Step = ({step,forgetPassword}) => {
  return (
    <div className="flex w-full justify-center items-center text-sm mb-9 sm:text-base sm:mb-10">
      <div
        className={`${step === 1 ? 'bg-yellow' : 'bg-gray1'} text-white h-8	w-8  rounded-full flex justify-center items-center`}>1
      </div>
      {
        forgetPassword? (
            step === 1 ? (
              <div className={`${step === 1 || 'text-gray1'} ml-2`}>填寫手機生日</div>
            ):null
        ):(
          <div className={`${step === 1 || 'text-gray1'} ml-2`}>基本資料</div>
        )
      }

      <div className={` ${forgetPassword? 'w-4 sm:w-16 mx-3 sm:mx-2':'w-8 sm:w-16 mx-6 sm:mx-2'} h-0.5 mx-6 sm:mx-2 bg-gray5`}/>
      <div
        className={` ${step === 2 ? 'bg-yellow' : 'bg-gray1'} text-white h-8	w-8 rounded-full flex justify-center items-center`}>2
      </div>
      {
        forgetPassword ? (
          step === 2 ? (
            <div className={`${step === 2 || 'text-gray1'} ml-2`}>手機驗證</div>
            ):null

        ):(
          <div className={`${step === 2 || 'text-gray1'} ml-2`}>手機驗證</div>
        )
      }
      {
        forgetPassword? (
          <>
            <div className={`${forgetPassword? 'w-4 sm:w-16 mx-3 sm:mx-2':'w-8 sm:w-16 mx-6 sm:mx-2'}   h-0.5  bg-gray5`}/>
            <div
              className={` ${step === 3 ? 'bg-yellow' : 'bg-gray1'} text-white h-8	w-8 rounded-full flex justify-center items-center`}>3
            </div>
            {
              forgetPassword? (
                step === 3 ? (
                  <div className={`${step === 3 || 'text-gray1'} ml-2`}>重新設定密碼</div>
                  ): null
              ) : null
            }

          </>
        ): null
      }

    </div>
  )
}

export default Step
