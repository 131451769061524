import {Button, DatePicker, Form, Input} from "antd";
import {useMemo} from "react";
import useWindowDimensions from '@/hook/useWindowDimensions'

const ForgetPasswordCheck = ({handleUpdatePassword})=>{

  const {width} = useWindowDimensions()
  const formWidth = useMemo(() => {
    if (width > 550) {
      return '500px'
    } else {
      return `${width - 32}px`
    }
  }, [width])

   return(
     <Form className='login-form' name={'form'} layout="vertical" style={{width: formWidth}} onFinish={handleUpdatePassword}>

       <Form.Item label="新密碼" name={'newPassword'} required rules={[{
         validator(_, v) {
           if (typeof v !== 'string' || v.length === 0) {
             return Promise.reject(new Error('請輸入新密碼'))
           }
           if (!/^(?=.*[A-z]+)(?=.*\d+).{6,12}$/.test(v)) {
             return Promise.reject(new Error('密碼格式必須為6-12碼英數字混合'))
           }
           return Promise.resolve()
         }
       }]} validateTrigger={['onChange', 'onBlur']}>
         <Input.Password placeholder="6-12碼英數字混合"/>
       </Form.Item>
       <Form.Item label="新密碼確認" name={'newPasswordCheck'} required rules={[({getFieldValue}) => ({
         validator(_, v) {
           if (typeof v !== 'string' || v.length === 0) {
             return Promise.reject(new Error('請輸入確認密碼'))
           }
           if (v !== getFieldValue('newPassword')) {
             return Promise.reject(new Error('確認密碼必須與新密碼相同'))
           }
           return Promise.resolve()
         }
       })]} validateTrigger={['onChange', 'onBlur']}>
         <Input.Password placeholder="6-12碼英數字混合"/>
       </Form.Item>


       <Form.Item>
         <Button type="primary" className={'border-none mt-6'} size='large' block htmlType={'submit'}>重新設定密碼</Button>
       </Form.Item>

     </Form>
   )
}

export default ForgetPasswordCheck
