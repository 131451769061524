import {Button, DatePicker, Form, Input} from 'antd'
import {useMemo} from 'react'
import {useHttp} from '@/pages/Promote/core/hooks/useHttp'
import useWindowDimensions from '@/hook/useWindowDimensions'
import {message} from "antd";

const ResetPassword = ({nextStep}) => {
  const {userApis} = useHttp()
  const {width} = useWindowDimensions()
  const formWidth = useMemo(() => {
    if (width > 550) {
      return '500px'
    } else {
      return `${width - 32}px`
    }
  }, [width])

  const handleSendSMS = async (d) => {
    if (d.birth) {
      d.birth = d.birth.format('YYYY-MM-DD')
    }
    const res = await userApis.resetVerify(d)
    const { success ,data } = res
    if (res.success) {
      nextStep(d)
    }else{
      message.warning(data.message)
    }

  }



  return (
    <>
      <Form className='login-form' name={'form'} layout="vertical" style={{width: formWidth}} onFinish={handleSendSMS}>

        <Form.Item label="手機" name={'phone'} rules={[{required: true}]} validateTrigger={['onChange', 'onBlur']}>
          <Input placeholder="請輸入您的手機號碼，共10碼" inputmode="decimal"/>
        </Form.Item>

        <div className='flex'>
          <div className='w-full pr-1 sm:pr-1.5'>
            <Form.Item label="出生日期" name={'birth'} rules={[{required: true}]} validateTrigger={['onChange', 'onBlur']}>
              <DatePicker className="w-full rounded-lg" placeholder="年/月/日" size='large'
                          format={(value) => value.format('YYYY-MM-DD')}/>
            </Form.Item>
          </div>

        </div>


        <Form.Item>
          <Button type="primary" className={'border-none mt-6'} size='large' block htmlType={'submit'}>下一步</Button>
        </Form.Item>

      </Form>
    </>
  )
}

export default ResetPassword
