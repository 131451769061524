import {CardBody, CardHead} from "@/pages/Checkout/components/card";
import {Input} from "antd";
const { TextArea } = Input
const Notes = props =>{
    const {data,setNotes} = props
    const handleDeliveryTime =e=>{
        setNotes({...data,hopeDeliveryTimePeriod:e.target.value})
    }
    const handleRemark = e=>{
        setNotes({...data,remark:e.target.value})
    }
    return (
        <div className='mt-3 sm:mt-6 relative w-full-half max-w-common-wrap '>
        <CardHead title={"收件備註"}></CardHead>
            <CardBody>
                <div className='flex flex-col justify-center items-center gap-4'>
                <select
                    className='w-full text-sm p-1 bg-white rounded-md border border-E0E0E0'
                    onChange={handleDeliveryTime}
                    required
                    defaultValue={""}
                >
                    <option disabled value={""}>選擇希望送達時段</option>
                    <option value={'NOT_SPECIFY'}>不指定</option>
                    <option value={'BEFORE_ONE_PM'}>13時前</option>
                    <option value={'ONE_PM_TO_SIX_PM'}>13-18時</option>
                </select>

                <TextArea onChange={handleRemark} className='w-full text-sm p-2 border border-E0E0E0'
                          placeholder="請輸入備註"/>
                </div>
            </CardBody>
        </div>
    )
}

export default Notes