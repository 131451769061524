import Logo from '@/images/logo.svg'
import Step from '../Promote/components/Step'
import UserInfo from './components/UserInfo'
import SendMessage from './components/SendMessage'
import {useHttp} from '@/pages/Promote/core/hooks/useHttp'
import {useEffect, useState} from 'react'
import {message} from 'antd'
import {useIdentifierPath} from '@/pages/Promote/core/helper'
import {useHistory, useLocation} from 'react-router-dom'
import {useAuth} from "@/pages/Promote/core/hooks/useAuth";

const Register = () => {
  const [registerInfo,setRegisterInfo] = useState(null)
  const [verifyError, setVerifyError] = useState(false)
  const {userApis} = useHttp()
  const history = useHistory()
  const location = useLocation()
  const {getIdentifierPath} = useIdentifierPath()
  const [step, setStep] = useState(1)
  const fromFB = location.state?.isFB
  const fromApple = location.state?.isAppleId
  const fromGoogle = location.state?.isGoogle
  const email = location.state?.email
  const setAuth = useAuth(e => e.setAuth)
  const setToken = useAuth(e => e.setToken)

  const handleSaveRegisterData = (data)=>{
    setRegisterInfo(data)
    setStep(2)
  }

  const handleResetPhone = async(phone)=>{
    if(phone){
      setRegisterInfo({...registerInfo,userPhone:phone})
    }
    let res
    if (fromFB) {
      res = await userApis.verifyFbSend(phone? {...registerInfo,userPhone:phone}: registerInfo)
    }else if (fromApple) {
      res = await userApis.verifyAppleIdSend(phone? {...registerInfo,userPhone:phone}: registerInfo)
    }else if (fromGoogle) {
      res = await userApis.verifyGoogleSend(phone? {...registerInfo,userPhone:phone}: registerInfo)
    } else{
      res = await userApis.sendSMS(phone? {...registerInfo,userPhone:phone}: registerInfo)
    }
    if (res.success) {
      if(res.data){
        message.success('重新發送成功!'+res.data)
      }else{
        message.success('重新發送成功!')
      }
    }
  }

  const handleSendMessage = async(verifyCode)=>{
    let result
    if(fromFB){
      result = await userApis.verifyFbRegister({...registerInfo,verifyCode,account:location.state.account})
    }else if(fromApple){
      result = await userApis.verifyAppleIdRegister({...registerInfo,verifyCode,account:location.state.account})
    }else if(fromGoogle){
      result = await userApis.verifyGoogleRegister({...registerInfo,verifyCode,account:location.state.account})
    }else{
      result = await userApis.verifyRegister({...registerInfo,verifyCode})
    }
    if(result.success){
      message.success('註冊成功!')
      setVerifyError(false)
      await onLogin(registerInfo)
      // history.push({
      //   pathname: getIdentifierPath() + '/Login'
      // })
    } else {
      setVerifyError(true)
    }
  }

  const onLogin = async (d) => {

    let res
    if(fromFB){
      res = await userApis.loginFB({accessToken: location.state.accessTokenData})
    }else if(fromApple){
      res = await userApis.loginAppleId({ identityToken: location.state.accessTokenData})
    }else if(fromGoogle){
      res = await userApis.loginGoogle({ accessToken: location.state.accessTokenData})
    }else{
      res = await userApis.login({
        account: d.userPhone,
        password: d.password
      })
    }
    if (res.success) {
      const {accessToken, ...auth} = res
      message.success('登入成功')
      setAuth(auth)
      setToken(accessToken)
      history.replace(getIdentifierPath())
    }
  }



  return (
    <div className="w-full h-full flex flex-col justify-center items-center px-4 pt-10 pb-28 sm:pt-20 sm:pb-24">
      <div >
        <img className={'h-8.5 sm:h-auto'} src={Logo} alt="logo" />
      </div>
      <div className="mt-2 mb-4 text-sm sm:my-4 sm:text-xl">會員註冊</div>
      <Step step={step} />
      {step === 1 ?
          <UserInfo email={email} fromFB={fromFB} fromApple={fromApple} fromGoogle={fromGoogle} nextStep={(data) => handleSaveRegisterData(data)} />
        :
          <SendMessage registerInfo={registerInfo} handleSendMessage={handleSendMessage} handleResetPhone={handleResetPhone} hasError={verifyError}  />
       }
    </div>
  )
}

export default Register
